import { Grid } from "@mui/material";
import { useEffect } from "react";
import {
  AutocompleteInput,
  RecordContextProvider,
  ReferenceInput,
  TextInput,
  required,
  useGetOne,
} from "react-admin";
import { useFormContext } from "react-hook-form";
import { MultiImageInput } from "~/components";
import ItinRichTextArea from "~/components/inputs/ItinRichTextArea";
import { ContentTypeForm } from "./ContentTypeForm";
import { BlockInput } from "~/components/inputs";

export const ContentForm = ({ target_type, ...rest }) => {
  const { setValue, watch } = useFormContext();
  const record = watch();
  const { content_type_id } = record;
  useEffect(() => {
    setValue("target_type", target_type);
  }, [target_type, setValue]);

  const { data: content_type } = useGetOne(
    "content_types",
    { id: content_type_id },
    { enabled: !!content_type_id }
  );

  return (
    <Grid
      container
      columnSpacing={2}
      sx={{ backgroundColor: "transparent" }}
      width={"50vw"}
    >
      <Grid item xs={12}>
        <TextInput source={"title"} defaultValue="" fullWidth />
      </Grid>
      <Grid item xs={6}>
        <ReferenceInput
          source={"content_type_id"}
          reference="content_types"
          filter={{ class_type: target_type }}
        >
          <AutocompleteInput
            create={<ContentTypeForm class_type={target_type} onSuccess />}
            optionText="name"
            validate={required()}
          />
        </ReferenceInput>
      </Grid>
      <Grid item xs={6}>
        <TextInput source={"url"} fullWidth />
      </Grid>
      <Grid item xs={12}>
        {content_type?.type.includes("Media") && (
          <RecordContextProvider value={record}>
            <MultiImageInput source={"media"} />
          </RecordContextProvider>
        )}
        {content_type?.type.includes("Markdown") && (
          <ItinRichTextArea
            placeholder="Additional Content.."
            source={"description"}
            isRequired={false}
          />
        )}
        {content_type?.type.includes("Editor") && (
          <BlockInput source="json_data" />
        )}
      </Grid>
    </Grid>
  );
};
