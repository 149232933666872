import { Grid } from "@mui/material";
import { RecentCheckIns } from "~/components";
import { useCurrentPlatform } from "~/context";
import { TagSidebar } from "~/resources/taggings";
import { SubscriptionOptions } from "~/stripe/SubscriptionOptions";
import { ItineraryHero } from "./ItineraryHero";
import { ItineraryMap } from "./ItineraryMap";
import { GridCard } from "~/components/GridCard";
import HasPlatformExtension from "~/components/HasPlatformExtension";
import { ItineraryStats } from "../ItineraryStats";

export const ItineraryOverview = () => {
  const { getTerm } = useCurrentPlatform();
  const itineraryTerm = getTerm("itinerary");
  const tagTerm = getTerm("tag", {
    plural: true,
    capitalize: true,
  });
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <GridCard sx={[{ height: "175px" }]}>
          <ItineraryHero />
          <SubscriptionOptions
            cta={`Upgrade this ${itineraryTerm} to take advantage of additional features.`}
          />
        </GridCard>
        <ItineraryStats />
        <HasPlatformExtension platform_extension="Contesting">
          <GridCard>
            <RecentCheckIns limit={10} />
          </GridCard>
        </HasPlatformExtension>
      </Grid>
      <Grid item xs={6}>
        <GridCard mb={2} sx={[{ height: "700px" }]}>
          <ItineraryMap />
        </GridCard>
        <GridCard title={tagTerm}>
          <TagSidebar />
        </GridCard>
      </Grid>
    </Grid>
  );
};
