import { Grid, Typography } from "@mui/material";
import { BooleanInput, FormTab, TextInput, required } from "react-admin";
import { AdminUserInput, EnumChoiceInput } from "~/components/inputs";
import { RegionInput } from "~/components/inputs/RegionInput";
import { useCurrentPlatform } from "~/context";
import { validateUrl } from "~/hooks";

export const EditBasicTab = (props) => {
  const { getTerm } = useCurrentPlatform();
  const eventsTerm = getTerm("event", { plural: true });
  const eventTerm = getTerm("event");
  return (
    <FormTab {...props} label="Basic info">
      <Grid container columnSpacing={3}>
        <Grid item xs={12} md={6}>
          <TextInput source="name" validate={required()} fullWidth />
        </Grid>
        <Grid item xs={6} md={6}>
          <RegionInput source="target_id" />
        </Grid>
        <Grid item xs={6} md={2}>
          <TextInput
            source="cost"
            validate={required()}
            fullWidth
            helperText={`Enter 0 for free ${eventsTerm}.`}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <TextInput label="Ticket URL" source="ticket_url" fullWidth />
        </Grid>
        <Grid item xs={6} md={3}>
          <EnumChoiceInput source="status" fullWidth isRadio />
        </Grid>
        <Grid item xs={6} md={3}>
          <BooleanInput
            source="featured"
            label={`Make this a featured ${eventTerm}`}
            sx={{ mt: 7 }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography variant="h5" mb="16px">
            Organizer Details
          </Typography>
        </Grid>
        <Grid item xs={6} md={6}>
          <AdminUserInput fullWidth />
        </Grid>
        <Grid item xs={6} md={6}>
          <TextInput source="email" fullWidth />
        </Grid>
        <Grid item xs={6} md={6}>
          <TextInput source="phone_number" fullWidth />
        </Grid>
        <Grid item xs={6} md={6}>
          <TextInput source="website" validate={validateUrl} fullWidth />
        </Grid>
      </Grid>
    </FormTab>
  );
};
