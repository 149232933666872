import { useEffect, useState } from "react";

export const useMousePosition = (track = true) => {
  const [mousePosition, setMousePosition] = useState({ x: null, y: null });
  useEffect(() => {
    const updateMousePosition = (ev) => {
      setMousePosition({ x: ev.clientX, y: ev.clientY });
    };
    if (track) {
      window.addEventListener("mousemove", updateMousePosition);
    } else {
      window.removeEventListener("mousemove", updateMousePosition);
    }
    return () => {
      window.removeEventListener("mousemove", updateMousePosition);
    };
  }, [track]);
  return mousePosition;
};
