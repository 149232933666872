import { Datagrid, ReferenceField, TextField } from "react-admin";
import AvatarField from "~/components/AvatarField";
import { EnumSelectField } from "~/components/fields";
import { ToggleList } from "~/components/lists";
import { useCurrentPlatform } from "~/context";
export const MediaList = () => {
  return (
    <ToggleList
      modes={MODES}
      sx={{ "& .ItinImageCard-image": { objectFit: "contain" } }}
    />
  );
};

const cardData = (record) => {
  return {
    title: `${record.filename}`,
    subtitles: [record.record_name],
    img: record,
  };
};

const ListData = () => {
  const { getTerm } = useCurrentPlatform();
  const regionTerm = getTerm("region", { capitalize: true, plural: false });
  return (
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <EnumSelectField source="status" />
      <AvatarField source="hero" label={"false"} />
      <TextField source="name" />
      <ReferenceField source="region_id" reference="regions" label={regionTerm}>
        <TextField source="name" />
      </ReferenceField>
    </Datagrid>
  );
};

const MODES = [
  { name: "Grid", cardData: cardData },
  { name: "List", view: <ListData /> },
];
