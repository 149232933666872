import { Box } from "@mui/material";
import {
  Edit,
  FormTab,
  TabbedForm,
  TextInput,
  required,
  useResourceContext,
} from "react-admin";
import { LocationFields, StyledToolBar } from "~/components";
import { ImageTabs } from "~/components/images";
import { EnumChoiceInput } from "~/components/inputs";
import ItinRichTextArea from "~/components/inputs/ItinRichTextArea";
import { useCurrentPlatform } from "~/context";
import { ContentInlineEditList } from "~/resources/contents";
import { LinkedDmos } from "../dmos";
import { LogList } from "../logs/LogList";
import { ParentRegionInput } from "./ParentRegionInput";
import { RegionOverview } from "./RegionOverView/RegionOverview";

export const RegionEdit = () => {
  const resource = useResourceContext();
  const { getTerm } = useCurrentPlatform();
  const resourceTerm = getTerm(resource);
  const resourcesTerm = getTerm(resource, { plural: true });
  // TODO ReferenceNodeInput label not working for parent region
  return (
    <Edit mutationMode="pessimistic" title=" " redirect={false}>
      <TabbedForm variant="standard" toolbar={<StyledToolBar />}>
        <FormTab label="overview">
          <RegionOverview />
        </FormTab>
        <FormTab label="basic info" sx={{ maxWidth: "50%" }}>
          <TextInput
            source="name"
            label="Name"
            validate={required()}
            fullWidth
          />
          <EnumChoiceInput source="status" fullWidth />
          <ParentRegionInput source="parent_region_id" />
        </FormTab>
        <FormTab label="content">
          <Box
            display="grid"
            sx={{ width: "100%" }}
            gridTemplateColumns="repeat(12,1fr)"
            gap={2}
          >
            <Box gridColumn={"span 12"}>
              <TextInput
                title="Subtitle"
                placeholder="Compose an epic.."
                source="subtitle"
                isRequired={false}
                fullWidth
                helperText={`A short tagline that goes beneath the name of the ${resourceTerm}. Example: 'More than you imagined!'`}
              />
            </Box>
            <Box gridColumn="span 6">
              <ItinRichTextArea
                label="Short Description"
                helperText={`A summary of the full description. Displayed where many ${resourcesTerm} are shown.`}
                placeholder="Compose an epic.."
                source="short_description"
                isRequired={false}
              />
            </Box>
            <Box gridColumn="span 6">
              <ItinRichTextArea
                label="Full Description"
                helperText={`A full description you would like associated with your ${resourceTerm}. This will appear on expanded views of your ${resourceTerm} for the ${getTerm(
                  "user"
                )}.`}
                placeholder="Compose an epic.."
                source="description"
                isRequired={false}
              />
            </Box>
          </Box>
          <ContentInlineEditList />
        </FormTab>
        <FormTab label="DMOs">
          <LinkedDmos />
        </FormTab>
        <FormTab label="Images">
          <ImageTabs />
        </FormTab>
        <FormTab
          label={`${getTerm(resource, {
            capitalize: true,
          })} Map`}
        >
          <LocationFields />
        </FormTab>

        {/* <FormTab label="Magic Content">
          <HasPlatformExtension
            platform_extension="AiExtension"
            fallback={"upgrade to gain AI superpowers!"}
          >
          <RegionFoundContent />
          </HasPlatformExtension>
        </FormTab> */}
        {/* <FormTab label="Analytics">
          <AnalyticsComponent />
        </FormTab> */}
        <FormTab label="Activity Log">
          <LogList />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default RegionEdit;
