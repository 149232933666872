import { Check, Trash } from "@phosphor-icons/react";
import {
  DeleteButton,
  SaveButton,
  Toolbar,
  useResourceContext,
} from "react-admin";
import { useCurrentPlatform } from "~/context";

export const StyledToolBar = ({
  showSave = true,
  showDelete = true,
  deleteButtonProps,
  saveButtonProps,
  ...props
}) => {
  const resource = useResourceContext();
  const { getTerm } = useCurrentPlatform();
  const label = getTerm(resource, { capitalize: true });

  return (
    <Toolbar {...props}>
      {showSave && (
        <SaveButton
          type="button"
          endIcon={<Check />}
          icon={null}
          {...saveButtonProps}
        />
      )}
      {showDelete && (
        <DeleteButton
          label={`Delete ${label}`}
          endIcon={<Trash />}
          startIcon={null}
          {...deleteButtonProps}
          confirmTitle={`Delete ${label}`}
          confirmContent={`Are you sure you want to permanently delete this ${getTerm(
            resource
          )}?`}
        />
      )}
    </Toolbar>
  );
};
