import { createTheme, createStyles } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import React from "react";
import clsx from "clsx";
const defaultTheme = createTheme();
const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        position: "relative",
        overflow: "hidden",
        width: "100%",
        height: 20,
        margin: 4,
      },
      value: {
        position: "absolute",
        lineHeight: "18px",
        width: "100%",
        display: "flex",
        justifyContent: "left",
        paddingLeft: 6,
        fontWeight: 700,
        fontSize: "0.9em",
      },
      bar: {
        borderRadius: 3,
        height: "100%",
        backgroundColor: "#6d88f4",
      },
    }),
  { defaultTheme }
);

export const ReportDataGridBar = React.memo(function ProgressBar(props) {
  const { value, max } = props;
  const valueInPercent = (value / max) * 100;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.value}>{value}</div>
      <div className={classes.bar} style={{ maxWidth: `${valueInPercent}%` }} />
    </div>
  );
});
export function renderProgress(params) {
  return <ProgressBar value={Number(params.value)} />;
}
