import { Box } from "@mui/material";
import { useGetOne } from "react-admin";
import { ItinLoading } from "~/components";
import { AdminHome } from "./AdminHome";
import { CollaboratorHome } from "./CollaboratorHome";

export const Home = () => {
  const { data: currentPlatformUser, isLoading: isPlatformUserLoading } =
    useGetOne("platform_users", { id: "current" });
  const { data: currentUser, isLoading: isUserLoading } = useGetOne("users", {
    id: "current",
  });

  if (isPlatformUserLoading || isUserLoading) return <ItinLoading />;
  if (
    currentPlatformUser?.role === "admin" ||
    currentUser?.role === "super_admin"
  ) {
    return (
      <Box>
        <AdminHome />
      </Box>
    );
  }
  if (currentPlatformUser?.role === "collaborator") {
    return (
      <Box>
        <CollaboratorHome platform_user={currentPlatformUser} />
      </Box>
    );
  }
  // We need to make a "Sign up for a platform" page if they have signed up
  // but not been added as a collaborator or admin to any platforms.
  if (!currentPlatformUser) return "hello";

  return "who are you?";
};
