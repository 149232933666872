import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { useHasPlatformExtension } from "~/components/HasPlatformExtension";
import { MenuItem } from "./MenuItem";
import { useStore } from "react-admin";
import { useGetIdentity } from "react-admin";

export const MenuSection = ({ menu_group, menu_resources, ...props }) => {
  const identity = useGetIdentity();
  const hasExtension = useHasPlatformExtension(menu_group.requiredExtension);

  if (menu_group.requiredExtension && !hasExtension) return null;
  if (
    menu_group.requiredRole &&
    !menu_group.requiredRole.includes(identity.role)
  )
    return null;

  return (
    <Accordion
      sx={{
        padding: "0",
        margin: "0",
      }}
      disableGutters
      {...props}
    >
      <AccordionSummary
        expandIcon={<ExpandMore color="primary" />}
        sx={{
          backgroundColor: "black",
          color: "white",
          fontSize: "16px",
          padding: "10",
          margin: "0",
        }}
      >
        {menu_group.name}
      </AccordionSummary>
      <AccordionDetails
        sx={{
          backgroundColor: "black",
          padding: 0,
          margin: "0",
        }}
      >
        {menu_resources.map((resource, i) => {
          return (
            <MenuItem
              key={`${resource.name}-${i}-menu-item`}
              resource={resource}
              indent={true}
            />
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
};
