import { EventCreate } from "./EventCreate";
import { EventEdit } from "./EventEdit";
import { EventList } from "./EventList";
import { ItinResourceProps } from "../types";
import { Calendar } from "@phosphor-icons/react";

export const EventResource: ItinResourceProps = {
  name: "events",
  list: EventList,
  edit: EventEdit,
  create: EventCreate,
  icon: <Calendar />,
  recordRepresentation: (r) => r.name,
  options: {menu_group: "events"}

};
