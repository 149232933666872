import { createContext, useContext, useMemo } from "react";
import { useGetOne, useRefresh, useUpdate } from "react-admin";
import { useQueryClient } from "react-query";
import { ItinLoading } from "~/components";
import { getTermFunction } from "~/helpers";

const CurrentPlatformContext = createContext({
  loading: true,
  platforms: [],
  currentPlatform: null,
  getTerm: (t) => t,
});

export const CurrentPlatformProvider = (props) => {
  const { data: currentPlatform, isLoading } = useGetOne("platforms", {
    id: "current",
  });
  const refresh = useRefresh();
  const queryClient = useQueryClient();

  const getTerm = useMemo(
    () => getTermFunction(currentPlatform?.terms),
    [currentPlatform?.terms]
  );

  const [update] = useUpdate();
  const setPlatform = async (current_platform_id) =>
    update(
      "users",
      {
        id: "current",
        data: { current_platform_id },
      },
      {
        onSuccess: (d) => {
          console.log("refreshing");
          queryClient.invalidateQueries([
            "platforms",
            "getOne",
            { id: "current" },
          ]);
          queryClient.invalidateQueries(["users", "getOne", { id: "current" }]);
          queryClient.invalidateQueries([
            "platform_users",
            "getOne",
            { id: "current" },
          ]);
          refresh();
        },
      }
    );
  if (isLoading) return <ItinLoading sx={{ height: "100vh" }} />;
  return (
    <CurrentPlatformContext.Provider
      {...props}
      value={{
        loading: isLoading,
        currentPlatform,
        setPlatform,
        getTerm,
      }}
    />
  );
};

export const useCurrentPlatform = () => useContext(CurrentPlatformContext);
