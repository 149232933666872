import { isFunction } from "lodash";
import { useCurrentPlatform } from "~/context";

export const useHasPlatformExtension = (platform_extension) => {
  const { currentPlatform } = useCurrentPlatform();
  const hasPlatformExtension = (extension) =>
    !!currentPlatform &&
    currentPlatform.platform_extensions.some(
      (pe) =>
        pe.type === extension ||
        extension === pe.type.replaceAll("Extension", "")
    );
  return platform_extension
    ? hasPlatformExtension(platform_extension)
    : hasPlatformExtension;
};

export const HasPlatformExtension = ({
  platform_extension,
  children,
  fallback = null,
}) => {
  const hasPlatformExtension = useHasPlatformExtension(platform_extension);
  if (isFunction(children)) return children(hasPlatformExtension);

  if (hasPlatformExtension) return children;

  return fallback;
};

export default HasPlatformExtension;
