import {
  CirclesFour,
  FilmStrip,
  FlagCheckered,
  ListBullets,
} from "@phosphor-icons/react";
import { Datagrid, NumberField, ReferenceField, TextField } from "react-admin";
import { CardCountIcon, SimpleMapList } from "~/components";
import AvatarField from "~/components/AvatarField";
import HasPlatformExtension from "~/components/HasPlatformExtension";
import {
  CircularProgressField,
  EnumSelectField,
  FeaturedField,
  StatsSummaryField,
  TaggingsField,
} from "~/components/fields";
import { EnumFilterInput, TagFilterInput } from "~/components/filter_inputs";
import { ToggleList } from "~/components/lists";
import { useCurrentPlatform } from "~/context";

export const ItineraryList = () => {
  return (
    <ToggleList
      modes={MODES}
      filters={[
        <TagFilterInput source="tags.id" label="By Tag" />,
        <EnumFilterInput source="status" label="By Status" />,
      ]}
    />
  );
};

const ListData = () => {
  const { getTerm } = useCurrentPlatform();
  const regionTerm = getTerm("region", { capitalize: true });
  const stopTerm = getTerm("stop", { capitalize: true, plural: true });
  const reelTerm = getTerm("reel", { capitalize: true });
  return (
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <EnumSelectField source="status" />
      <AvatarField source="hero" label={""} />
      <TextField source="name" />
      <TextField source="subtitle" sx={{ fontSize: "0.9em" }} />
      <ReferenceField source="region_id" reference="regions" label={regionTerm}>
        <TextField source="name" />
      </ReferenceField>
      <NumberField source="num_stops" label={stopTerm} sortable={false} />
      <FeaturedField source="featured" />
      <FeaturedField
        source="publish_as_reel"
        label={reelTerm}
        icon={<FilmStrip />}
      />
      <TaggingsField source="tags" sortable={false} />
      <CircularProgressField
        source="record_completion_score.score"
        tooltipSource={"record_completion_score.deficiencies"}
        textAlign="center"
        sortable={false}
        label="Score"
      />
      <HasPlatformExtension
        platform_extension="AnalyticsExtension"
        label="30 Days"
      >
        <StatsSummaryField label="30 Days" />
      </HasPlatformExtension>
    </Datagrid>
  );
};

const cardData = (record) => {
  return {
    title: record.name,
    subtitles: [
      <CardCountIcon icon={CirclesFour} type={"leg"} count={record.num_legs} />,
      <CardCountIcon
        icon={ListBullets}
        type={"stop"}
        count={record.num_stops}
      />,
      <CardCountIcon
        count={record.num_challenges}
        type={"Challenge"}
        icon={FlagCheckered}
      />,
    ],
    stats_summary: (
      <HasPlatformExtension
        platform_extension="AnalyticsExtension"
        label="30 Days"
      >
        <StatsSummaryField label="30 Days" />
      </HasPlatformExtension>
    ),
  };
};

const MODES = [
  { name: "Grid", cardData },
  { name: "List", view: <ListData /> },
  {
    name: "Map",
    view: (
      <SimpleMapList navigateOnClick cardData={cardData} showPolygons={false} />
    ),
  },
];
