import { TextField } from "@mui/material";
import { startCase } from "lodash";
import { useInput } from "react-admin";

export const LonLatField = ({ name, label = "", placeholder, ...props }) => {
  const {
    field,
    fieldState: { isTouched, error },
    formState: { isSubmitted },
    isRequired,
  } = useInput({
    source: name,
    ...props,
  });

  return (
    <TextField
      {...field}
      label={label}
      placeholder={placeholder}
      error={(isTouched || isSubmitted) && !!error}
      helperText={(isTouched || isSubmitted) && error ? error.message : ""}
      required={isRequired}
      fullWidth
    />
  );
};
