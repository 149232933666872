import { Box } from "@mui/material";
import {
  ReferenceField,
  ReferenceManyField,
  RichTextField,
  SimpleForm,
  TextField,
  useRecordContext,
} from "react-admin";
import { ImageField } from "~/components";
import { DrawerDatagrid } from "~/components/drawer";
import { useTargetType } from "~/hooks";
import { ContentForm } from "./ContentForm";

export const ContentList = () => {
  const target_type = useTargetType();
  const record = useRecordContext();
  return (
    <Box display="flex" flexDirection="column" width="100%">
      <ReferenceManyField
        reference="contents"
        target="target_id"
        filter={{ target_type }}
      >
        <DrawerDatagrid
          Form={
            <SimpleForm defaultValues={{ target_type, target_id: record.id }}>
              <ContentForm target_type={target_type} />
            </SimpleForm>
          }
        >
          <TextField source="title" />
          <ReferenceField
            source="content_type_id"
            reference="content_types"
            filter={{ class_type: target_type }}
          >
            <TextField source="name" />
          </ReferenceField>
          <RichTextField source="description" />
          <ImageField source="media" height={75} label="Media" />
        </DrawerDatagrid>
      </ReferenceManyField>
    </Box>
  );
};
