import {
  useCreatePath,
  useRecordContext,
  useResourceContext,
} from "react-admin";
import { useNavigate } from "react-router-dom";

export const useNavigateToRecord = ({ type = "edit", ...props } = {}) => {
  const resource = useResourceContext(props);
  const { id } = useRecordContext(props);
  const createPath = useCreatePath();
  const path = createPath({ resource, id, type });
  const navigate = useNavigate();
  const navigateToRecord = () => {
    navigate(path);
  };
  return navigateToRecord;
};
