import { Box, Tooltip } from "@mui/material";
import {
  AppleLogo,
  Books,
  ChartLineUp,
  FacebookLogo,
  FlagCheckered,
  Gear,
  Handshake,
  Newspaper,
  User,
} from "@phosphor-icons/react";
import { Menu } from "@react-admin/ra-rbac";
import { filter, merge } from "lodash";
import { useEffect } from "react";
import {
  useResourceDefinitionContext,
  useResourceDefinitions,
  useStore,
} from "react-admin";
import { useLocation } from "react-router-dom";
import { useCurrentPlatform } from "~/context";
import { Logo } from "~/images/Logo";
import { LayoutClasses } from "../layoutClasses";
import { MenuSection } from "./MenuSection";

const MENU_GROUPS = [
  {
    key: "places",
    name: "Places",
    primary_resource: "regions",
    defaultExpanded: true,
  },
  {
    key: "events",
    name: "Events",
    primary_resource: "events",
    defaultExpanded: true,
    requiredExtension: "Events",
  },
  {
    key: "content",
    name: "Content",
    primary_resource: "contents",
    defaultExpanded: true,
  },
  {
    key: "collaborate",
    name: "Collaborate",
    primary_resource: "platforms",
    defaultExpanded: true,
  },
  {
    key: "communicate",
    name: "Communicate",
    primary_resource: "newsletters",
    defaultExpanded: true,
  },
  {
    key: "contesting",
    name: "Contesting",
    primary_resource: "challenges",
    defaultExpanded: false,
    requiredExtension: "Contesting",
  },
  {
    key: "reports",
    name: "Reports",
    primary_resource: "analytics",
    defaultExpanded: false,
  },
  {
    key: "admin",
    name: "Admin",
    defaultExpanded: false,
    //    requiredRole: ["admin", "super_admin"],
  },
];

const useSetResourceLabels = () => {
  const { definitions: resources, register } = useResourceDefinitionContext();
  const { currentPlatform, getTerm } = useCurrentPlatform();
  useEffect(() => {
    if (currentPlatform?.id) {
      Object.entries(resources).forEach(([resource, props]) => {
        const { options } = props;
        const label = getTerm(resource, { plural: true, capitalize: true });
        if (label !== options?.label) {
          register(merge({}, props, { options: { label } }));
        }
      });
    }
  }, [resources, getTerm, register, currentPlatform?.id]);
};

export const Sidebar = () => {
  const resources = useResourceDefinitions();
  const location = useLocation();
  const [expanded, setExpanded] = useStore(`menu.accordion`);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };
  useEffect(() => {
    // Determine which accordion group should be expanded based on the current path
    MENU_GROUPS.forEach((mg) => {
      const resources = filter(
        menu_resources,
        (v) => v.options?.menu_group === mg.key
      );

      const shouldExpand = resources.some(
        (resource) => location.pathname.split("/")[1] == resource.name
      );
      if (shouldExpand) {
        setExpanded(mg.key);
      }
    });
  }, [location.pathname]);

  //I'm not convinced that setting resource labels in the admin is a good thing...
  //useSetResourceLabels();
  const { currentPlatform } = useCurrentPlatform();
  const menu_resources = {
    ...resources,
    platform: {
      icon: <Gear />,
      name: "platforms",
      options: {
        menu_group: "admin",
        label: "Platform Settings",
        resource_id: currentPlatform?.id,
      },
    },

    content_report: {
      icon: <Books />,
      name: "web",
      to: "/reports/content",
      options: {
        menu_group: "reports",
        label: "Content Summary",
      },
    },
    web_report: {
      icon: <ChartLineUp />,
      name: "web",
      to: "/reports/web",
      options: {
        menu_group: "reports",
        label: "Web Traffic",
      },
    },
    app_report: {
      icon: <AppleLogo />,
      name: "app",
      to: "/reports/app",
      options: {
        menu_group: "reports",
        label: "App Activity",
      },
    },
    user_feedback: {
      icon: <User />,
      name: "user_feedback",
      to: "/reports/user_feedback",
      options: {
        menu_group: "reports",
        label: "User Feedback",
      },
    },
    collaboration_report: {
      icon: <Handshake />,
      name: "collaboration",
      to: "/reports/collaboration",
      options: {
        menu_group: "reports",
        label: "Collaboration",
      },
    },
    contest_report: {
      icon: <FlagCheckered />,
      name: "contest",
      to: "/reports/contest",
      options: {
        menu_group: "reports",
        label: "Contests",
      },
    },
    email_report: {
      icon: <Newspaper />,
      name: "contest",
      to: "/reports/communication",
      options: {
        menu_group: "reports",
        label: "Communications",
      },
    },
    socials_report: {
      icon: <FacebookLogo />,
      name: "socials",
      to: "/reports/socials",
      options: {
        menu_group: "reports",
        label: "Socials",
      },
    },
    // videos: {
    //   icon: <VideoCamera />,
    //   name: "Videos",
    //   options: { menu_group: "content" },
    // },
    // posts: {
    //   icon: <Scroll />,
    //   name: "Posts",
    //   options: { menu_group: "content" },
    // },
    // notifications: {
    //   icon: <BellRinging />,
    //   name: "Notifications",
    //   options: { menu_group: "communicate" },
    // },
    // audio: {
    //   icon: <SpeakerHigh />,
    //   name: "Sound Clips",
    //   options: { menu_group: "content" },
    // },
  };

  return (
    <div className={LayoutClasses.sidebar}>
      <Tooltip followCursor title="Dashboard">
        <Box
          as="a"
          href={"/"}
          sx={{ marginX: 9, maxHeight: 105, cursor: "pointer" }}
        >
          <Logo className={LayoutClasses.logo} />
        </Box>
      </Tooltip>
      <Menu className={LayoutClasses.menu}>
        {MENU_GROUPS.map((mg) => (
          <MenuSection
            key={mg.key}
            menu_group={mg}
            menu_resources={filter(
              menu_resources,
              (v) => v.options?.menu_group === mg.key
            )}
            expanded={expanded === mg.key}
            onChange={handleAccordionChange(mg.key)}
          />
        ))}

        {/* <MenuItem resource={menu_resources["help_topics"]} key="help_topics" />; */}
      </Menu>
    </div>
  );
};
