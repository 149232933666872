import { Button, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { useRecordContext, useResourceContext } from "react-admin";
import ConfettiExplosion from "react-confetti-explosion";
import { useCurrentPlatform } from "~/context";
import { timeAgo, timeAgoMinutes } from "~/helpers/timeAgo";
import { useRecordCommunicate } from "~/hooks";
import { GridCard } from "../GridCard";
import { Sparkles } from "../Sparkles";

export const InviteCollaborator = (props) => {
  const [isExploding, explode] = useState(false);
  const resource = useResourceContext(props);
  const { getTerm } = useCurrentPlatform();
  const resourceTerm = getTerm(resource);

  const record = useRecordContext(props);
  const { mutate } = useRecordCommunicate("invite_collaborator", props);

  return (
    <GridCard variant="outlined">
      <Grid container>
        <Grid item xs={9}>
          <Typography variant="h5">Invite Collaborator</Typography>
          <p>
            This {resourceTerm} is unclaimed! You can invite {record.email} to
            claim it.
            {record.invited_at && ` Invited ${timeAgo(record.invited_at)} Ago`}
          </p>
        </Grid>
        <Grid item xs={3}>
          <Sparkles show={true}>
            {isExploding && (
              <ConfettiExplosion
                colors={["#F7A7EA", "#F7DF96", "#B3B3FD"]}
                zIndex={10}
                duration={2000}
                particleCount={40}
                width={600}
                style={{ position: "absolute", left: "25%" }}
                onComplete={() => explode(false)}
              />
            )}

            <Button
              variant="contained"
              disabled={timeAgoMinutes(record.invited_at) < 5}
              onClick={mutate}
            >
              {timeAgoMinutes(record.invited_at) < 5
                ? "Please wait a few minutes"
                : "Invite Now"}
            </Button>
          </Sparkles>
        </Grid>
      </Grid>
    </GridCard>
  );
};
