import { Box, Grid, Tooltip, Typography } from "@mui/material";
import dayjs from "dayjs";
import { Calendar, Clock } from "@phosphor-icons/react";
import { useRecordContext } from "react-admin";
import { showDate } from "~/helpers/showDate";

export const EventTimeSnippet = (props) => {
  const record = useRecordContext(props);
  const start = dayjs(record.start);
  const end = dayjs(record.last_end_time ? record.last_end_time : record.end);
  const date_range = [start.format("ddd, MMM D"), end.format("ddd, MMM D")];
  const end_year = end.format("YYYY");
  const time_range = [start.format("H:mm a"), end.format("H:mm a")];
  return (
    <Box>
      <Tooltip
        title={record.upcoming_times?.map((ut) => (
          <Grid key={ut} sx={{ fontSize: 12 }}>
            {showDate(ut)}
          </Grid>
        ))}
      >
        <Box>
          <Typography display="flex" alignItems="center" color="grey.600">
            <Calendar size={12} style={{ marginRight: 2 }} weight="fill" />
            {`${[...new Set(date_range)].join(" - ")} ${end_year}`}
          </Typography>
          {!record.all_day && (
            <Typography display="flex" alignItems="center" color="grey.600">
              <Clock size={12} style={{ marginRight: 2 }} />
              {time_range.join(" - ")}
            </Typography>
          )}
        </Box>
      </Tooltip>
    </Box>
  );
};
