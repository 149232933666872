import { Grid } from "@mui/material";
import {
  BooleanField,
  Datagrid,
  DateField,
  ImageField,
  Labeled,
  NumberField,
  ReferenceField,
  ReferenceManyField,
  Show,
  SimpleShowLayout,
  TextField,
  useRecordContext,
} from "react-admin";
import { EnumSelectField, PlatformUserField } from "~/components/fields";
import { GridCard } from "~/components/GridCard";
import { FormFieldTypeField } from "../form_fields";

export const FormEntryShow = () => {
  return (
    <Show>
      <SimpleShowLayout>
        <Grid container>
          <Grid item xs={9}>
            <ReferenceManyField
              reference="form_entry_fields"
              target="form_entry_id"
            >
              <Datagrid bulkActionButtons={false}>
                <TextField source="label" sortable={false} />
                <FormEntryFieldValueField source="value" sortable={false} />
                <FormFieldTypeField source="type" sortable={false} />
              </Datagrid>
            </ReferenceManyField>
          </Grid>
          <GridCard item xs={3}>
            <Labeled label="Form" fullWidth sx={{ marginBottom: 5 }}>
              <ReferenceField reference="forms" source="form_id" />
            </Labeled>
            <Labeled label="Submitted by" fullWidth sx={{ marginBottom: 5 }}>
              <PlatformUserField source="platform_user_id" />
            </Labeled>
            <Labeled label="Submitted Date" fullWidth sx={{ marginBottom: 5 }}>
              <DateField source="created_at" />
            </Labeled>
            <EnumSelectField
              source="status"
              color_options={{
                awaiting_approval: "primary",
                approved: "success",
                denied: "error",
                spam: "default",
              }}
            />
          </GridCard>
        </Grid>
      </SimpleShowLayout>
    </Show>
  );
};

const fieldByType = (props) => {
  const record = useRecordContext();
  const base_props = { sx: { fontWeight: "700" } };
  switch (record.type) {
    case "TextField":
    case "SelectField":
    case "MultiSelectField":
      return <TextField {...base_props} {...props} />;
    case "NumberField":
      return <NumberField {...base_props} {...props} />;
    case "DateField":
      return <DateField {...base_props} {...props} />;
    case "DateTimeField":
      return <DateField showTime {...base_props} {...props} />;
    case "ImageField":
      return (
        <ImageField
          src="url"
          {...base_props}
          {...props}
          sx={{
            "& .RaImageField-list": {
              paddingLeft: 0,
              "& .RaImageField-image": { width: "auto" },
            },
          }}
        />
      );
    case "BooleanField":
      return <BooleanField {...base_props} {...props} />;
    default:
      return <TextField {...base_props} {...props} />;
  }
};

const FormEntryFieldValueField = (props) => {
  return <>{fieldByType(props)}</>;
};
