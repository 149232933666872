import { Box, Grid, Tooltip } from "@mui/material";
import { ReferenceManyInput } from "@react-admin/ra-relationships";
import { get } from "lodash";
import {
  AutocompleteInput,
  BooleanInput,
  Datagrid,
  DateField,
  Edit,
  FormTab,
  Labeled,
  ReferenceInput,
  ReferenceManyField,
  required,
  SimpleFormIterator,
  TabbedForm,
  TextField,
  TextInput,
  useChoicesContext,
  useRecordContext,
} from "react-admin";
import { useWatch } from "react-hook-form";
import { StyledToolBar } from "~/components";
import { PolymorphicReferenceField } from "~/components/fields";
import { EnumChoiceInput } from "~/components/inputs";

export const ContentTypeEdit = () => {
  return (
    <Edit mutationMode="pessimistic" redirect={false}>
      <TabbedForm
        variant="standard"
        toolbar={<StyledToolBar showDelete={false} />}
      >
        <FormTab label="Basic Setup">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextInput
                    source="name"
                    label="Name"
                    fullWidth
                    helperText="This is the name of the content, it won't show up on the app or web, it's just for admins"
                    validate={[required()]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    source="description"
                    fullWidth
                    multiline
                    helperText="This will show to the admin's to help prompt them to enter relevant content"
                  />
                </Grid>
                <Grid item xs={12}>
                  <EnumChoiceInput
                    fullWidth
                    label="Record Type"
                    source="class_type"
                    validate={[required()]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <EnumChoiceInput fullWidth source="type" />
                </Grid>
                <Grid item xs={6}>
                  <Labeled label="Include Title Field">
                    <BooleanInput
                      source="use_title"
                      helperText="Select this option if you want to be able to add a title for each instance of this content"
                    />
                  </Labeled>
                </Grid>
                <Grid item xs={6}>
                  <Labeled label="Auto Create This Field">
                    <BooleanInput
                      source="auto_create"
                      helperText="Select this option if you want to automatically create this content type for each instance of the record type"
                    />
                  </Labeled>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <ContentTypeHooks />
            </Grid>
          </Grid>
        </FormTab>
        <FormTab label="Content">
          <ReferenceManyField reference="contents" target="content_type_id">
            <Datagrid bulkActionButtons={false}>
              <PolymorphicReferenceField
                source="target_id"
                reference_source="target_type"
              />
              <TextField source="title" label="Title" />
              <TextField source="description" label="Description" />
              <DateField source="updated_at" label="Last Updated" />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

const ContentTypeHooks = () => {
  const class_type = useWatch({ name: "class_type" });
  return (
    <ReferenceManyInput reference="content_type_hooks" target="content_type_id">
      <SimpleFormIterator inline disableClear>
        <ReferenceInput
          source="content_hook_id"
          reference="content_hooks"
          filter={{ class_type }}
        >
          <AutocompleteInput
            noOptionsText="No Hooks For This Record Type"
            label={false}
            sx={{ width: "50%" }}
            validate={[required()]}
            optionText={<ContentTypeOption />}
            inputText={(record) => record.name}
            helperText={<ContentTypeDescription />}
          />
        </ReferenceInput>
        <EnumChoiceInput source="display_status" isRadio label={false} />
      </SimpleFormIterator>
    </ReferenceManyInput>
  );
};

const ContentTypeDescription = () => {
  const { selectedChoices } = useChoicesContext();
  return get(selectedChoices, "[0].description", null);
};

const ContentTypeOption = () => {
  const record = useRecordContext();
  return (
    <Tooltip title={record.description} placement="right" arrow>
      <Box width="100%">{record.name}</Box>
    </Tooltip>
  );
};

// before_
// after_
// replace_

// Listing Detail Page:
// operating_hours
// title
// full_description
// hero_image
// carousel_images
// page_bottom
// page_top
// book_now

// Listing Card:
// card_title
// card_subtitle
// card_bottom
// card_top
