import { useCanAccess } from "@react-admin/ra-rbac";
import { ReferenceManyToManyField } from "@react-admin/ra-relationships";
import pluralize from "pluralize";
import { useState } from "react";
import {
  AutocompleteInput,
  ImageField,
  TextField,
  UrlField,
  useCreate,
  useGetList,
  useGetManyReference,
  useListContext,
  useRecordContext,
  useResourceContext,
} from "react-admin";
import { useFormContext } from "react-hook-form";
import { useQueryClient } from "react-query";
import { Empty } from "~/components";
import { DrawerDatagrid } from "~/components/drawer";
import { DmoForm } from "./DmoForm";
import { useDebounceValue } from "usehooks-ts";
export const LinkedDmos = () => {
  const record = useRecordContext();
  const resource = useResourceContext();
  const resource_single = pluralize(resource, 1);
  const join_table = `dmos_${resource}`;
  const join_id = `${resource_single}_id`;
  const queryClient = useQueryClient();
  const [defaultName, setDefaultName] = useState("");
  const [create] = useCreate();
  const { setValue } = useFormContext();

  const onCreate = async (data) =>
    create(
      join_table,
      { data: { dmo_id: data.id, [join_id]: record.id } },
      {
        onSettled: () =>
          queryClient
            .invalidateQueries([join_table, "getManyReference"])
            .then(() => {
              setValue("added_dmo", undefined);
            }),
      }
    );
  const { data: dmo_joins = [] } = useGetManyReference(join_table, {
    target: join_id,
    id: record.id,
    pagination: { page: 1, perPage: null },
    sort: {
      field: "id",
      order: "DESC",
    },
    filter: null,
  });

  const { canAccess: canUseResource } = useCanAccess({
    resource: "dmos",
    action: "edit",
  });

  return (
    <ReferenceManyToManyField
      reference="dmos"
      through={join_table}
      using={`${join_id},dmo_id`}
    >
      <DrawerDatagrid
        canEdit={canUseResource}
        buttonProps={(record, _resource, type) => {
          if (type === "delete") {
            return {
              resource: join_table,
              record: dmo_joins.find((join) => join.dmo_id === record.id),
              confirmTitle: `Remove DMO?`,
              confirmContent: `Are you sure you want to remove the DMO from this ${resource_single}?`,
            };
          }
        }}
        datagridProps={{ empty: <Empty showButton={false} /> }}
        Form={<DmoForm defaultName={defaultName} />}
        onCreate={onCreate}
        CustomCreateButton={
          <CreateButton onCreate={onCreate} setName={setDefaultName} />
        }
      >
        <ImageField source="logo.url" label="Logo" />
        <TextField source="name" />
        <UrlField source="website" target="_blank" />
      </DrawerDatagrid>
    </ReferenceManyToManyField>
  );
};

const CreateButton = ({ onCreateClick, onCreate, setName }) => {
  const [filter, setFilter] = useDebounceValue("", 500);
  const { data: selected_dmos = [] } = useListContext();
  const selected_dmo_ids = selected_dmos.map((d) => d.id);
  const { data: all_dmos = [], isFetching } = useGetList("dmos", {
    filter: { q: filter },
    sort: { field: "name", order: "ASC" },
  });
  const filtered_dmos = all_dmos.filter(
    (dmo) => !selected_dmo_ids.includes(dmo.id)
  );
  return (
    <AutocompleteInput
      source="added_dmo"
      label="Add a DMO"
      choices={filtered_dmos}
      variant="outlined"
      optionText="name"
      fullWidth
      onInputChange={(e) => setFilter(e?.target?.value || "")}
      onChange={(id) => {
        if (id !== "@@ra-create") {
          onCreate({ id });
        }
      }}
      onCreate={(val) => {
        setName(val);
        onCreateClick();
      }}
      isFetching={isFetching}
    />
  );
};
