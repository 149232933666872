import { useDefineAppLocation } from "@react-admin/ra-navigation";
import { Show, TabbedShowLayout } from "react-admin";
import { Stats } from "~/components";
import { TourismoDateRangePicker } from "~/components/TourismoDateRangePicker";
import { useCurrentPlatform } from "~/context";
import { ReportFilterProvider } from "~/context/ReportFilterContext";

export const WebReport = () => {
  useDefineAppLocation("reports.web");
  const { currentPlatform, isLoading } = useCurrentPlatform();
  if (isLoading) return "";

  return (
    <ReportFilterProvider>
      <TourismoDateRangePicker />
      <Show resource="platforms" id={currentPlatform.id} actions={false}>
        <TabbedShowLayout>
          <TabbedShowLayout.Tab label="Web Traffic">
            <Stats category="web" />
          </TabbedShowLayout.Tab>
          <TabbedShowLayout.Tab label="Web Sources">
            <Stats category="web_sources" />
          </TabbedShowLayout.Tab>
          <TabbedShowLayout.Tab label="Web Audience">
            <Stats category="web_audience" />
          </TabbedShowLayout.Tab>
        </TabbedShowLayout>
      </Show>
    </ReportFilterProvider>
  );
};
