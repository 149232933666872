import {
  AutocompleteInput,
  Datagrid,
  FunctionField,
  NumberField,
} from "react-admin";
import { SimpleMapList } from "~/components";
import AvatarField from "~/components/AvatarField";
import {
  CircularProgressField,
  EnumSelectField,
  TaggingsField,
} from "~/components/fields";
import { EnumFilterInput, TagFilterInput } from "~/components/filter_inputs";
import { ToggleList } from "~/components/lists";
import { useCurrentPlatform } from "~/context";
import { useParentRegions } from "~/hooks";
import { regionCardData } from "./RegionCardData";

const useFilters = () => {
  const { data = [] } = useParentRegions();
  const { getTerm } = useCurrentPlatform();
  const term = getTerm("region", { capitalize: true });
  return [
    <EnumFilterInput source="status" label="By Status" />,
    <TagFilterInput source="tags.id" label="By Tag" />,
    <AutocompleteInput
      source="parent_region_id"
      label={`By Parent ${term}`}
      optionText="name"
      optionValue="id"
      choices={data}
      emptyText={`By Parent ${term}`}
    />,
  ];
};

export const RegionList = () => {
  const filters = useFilters();
  return <ToggleList filters={filters} modes={MODES} />;
};

const ListData = () => {
  const { getTerm } = useCurrentPlatform();
  const listingsTerm = getTerm("listing", { capitalize: true, plural: true });
  const itinerariesTerm = getTerm("itinerary", {
    capitalize: true,
    plural: true,
  });
  const tagsTerm = getTerm("tag", {
    capitalize: true,
    plural: true,
  });
  return (
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <AvatarField source="hero" label={false} />
      <FunctionField
        source="name"
        render={(record) =>
          `${record.parent_region_name || ""}${
            record.parent_region_name ? ":" : ""
          } ${record.name}`
        }
      />
      <EnumSelectField source="status" />
      <TaggingsField source="tags" label={tagsTerm} sortable={false} />
      <NumberField
        source="listing_count"
        label={listingsTerm}
        sortable={false}
      />
      <NumberField
        source="itinerary_count"
        label={itinerariesTerm}
        sortable={false}
      />
      <CircularProgressField
        source="record_completion_score.score"
        tooltipSource="record_completion_score.deficiencies"
        textAlign="center"
        sortable={false}
        label="Score"
      />
    </Datagrid>
  );
};

const MODES = [
  { name: "Grid", cardData: regionCardData },
  { name: "List", view: <ListData /> },
  {
    name: "Map",
    view: (
      <SimpleMapList
        navigateOnClick
        cardData={regionCardData}
        showPins={false}
      />
    ),
  },
];

export default RegionList;
