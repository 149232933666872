import { Chat, DeviceMobile, Envelope } from "@phosphor-icons/react";
import { Chip } from "@mui/material";

import {
  BooleanField,
  Datagrid,
  DateField,
  FunctionField,
  List,
  NumberField,
  ReferenceField,
  ReferenceManyField,
  TextField,
  useRecordContext,
} from "react-admin";
import { Empty } from "~/components";
import {
  EnumField,
  PolymorphicReferenceField,
  StatsSummaryField,
} from "~/components/fields";
import { EnumChoiceInput } from "~/components/inputs";
import { useTargetType } from "~/hooks";

const filters = [
  <EnumChoiceInput
    label="Category"
    source="category"
    emptyText="Category"
    isRequired={false}
    SelectProps={{ displayEmpty: true, notched: false }}
  />,
];

export const CommunicationList = () => {
  return (
    <List filters={filters}>
      <CommunicationData />
    </List>
  );
};

export const RecordCommunicationList = () => {
  const target_type = useTargetType();

  return (
    <ReferenceManyField
      reference="communications"
      target="target_id"
      filter={{ target_type }}
    >
      <CommunicationData target_type={target_type} />
    </ReferenceManyField>
  );
};

export const CommunicationData = ({ target_type, ...props }) => {
  const record = useRecordContext(props);
  return (
    <Datagrid
      bulkActionButtons={false}
      rowClick="edit"
      empty={
        <Empty
          defaultValues={
            record && target_type
              ? { target_id: record.id, target_type }
              : undefined
          }
        />
      }
    >
      <EnumField source="status" />
      <DateField source="scheduled_at" />
      <DateField source="sent_at" />
      {!record && (
        <PolymorphicReferenceField
          source="target_id"
          reference_source="target_type"
        />
      )}
      <TextField source="name" label="Name" />
      <TextField source="subtitle" label="Subtitle" />

      <ReferenceField source="owner_id" reference="users" sortable={false} />
      <FunctionField
        label="Push"
        render={(record) =>
          record.send_push_notifications ? (
            <Chip
              variant={"outlined"}
              icon={<DeviceMobile />}
              label={record.communicatable_push_user_count}
            />
          ) : (
            "-"
          )
        }
      />
      <FunctionField
        label="Email"
        render={(record) =>
          record.send_email ? (
            <Chip
              variant={"outlined"}
              icon={<Envelope size={20} />}
              label={record.communicatable_email_user_count}
            />
          ) : (
            "-"
          )
        }
      />
      <FunctionField
        label="SMS"
        render={(record) =>
          record.send_sms ? (
            <Chip
              variant={"outlined"}
              icon={<Chat size={20} />}
              label={record.communicatable_sms_user_count}
            />
          ) : (
            "-"
          )
        }
      />
      <StatsSummaryField />
    </Datagrid>
  );
};
