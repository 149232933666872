import { createContext, useContext, useState } from "react";
import { Edit, FormTab, TabbedForm } from "react-admin";
import { ImageTabs, Stats, StyledToolBar } from "~/components";
import { useCurrentPlatform } from "~/context";
import { LogList } from "../logs/LogList";
import { ItineraryOverview } from "./ItineraryOverview/ItineraryOverview";
import { EditBasicTab, EditContentTab, EditStopsTab } from "./edit";
import { LinkedDmos } from "../dmos";

export const ItineraryEdit = () => {
  const [stopRef, setStopRef] = useState();
  const { getTerm } = useCurrentPlatform();
  const userTerm = getTerm("user", { capitalize: true });
  return (
    <StopDrawerContext.Provider value={{ ...stopRef, setStopRef }}>
      <Edit mutationMode="pessimistic" title=" " redirect={false}>
        <TabbedForm
          redirect={false}
          variant="standard"
          toolbar={<StyledToolBar />}
        >
          <FormTab label="Overview">
            <ItineraryOverview />
          </FormTab>
          <EditBasicTab />
          <EditContentTab />
          <EditStopsTab />
          <FormTab label="DMOs">
            <LinkedDmos />
          </FormTab>
          <FormTab label="Images">
            <ImageTabs />
          </FormTab>
          <FormTab label="Reports">
            {/* <UserInteractionsItinerary /> */}
            <Stats category="report" />
          </FormTab>
          {/* <FormTab label="Analytics">
          <AnalyticsComponent />
        </FormTab> */}
          <FormTab label="Activity Log">
            <LogList />
          </FormTab>
        </TabbedForm>
      </Edit>
    </StopDrawerContext.Provider>
  );
};

const StopDrawerContext = createContext(undefined);

export const useStopDrawerContext = () => {
  const context = useContext(StopDrawerContext);
  if (context === undefined)
    throw new Error(
      "useStopDrawerContext cannot be used outside a StopDrawerProvider"
    );
  return context;
};
