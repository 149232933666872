import { Link, useCreatePath, useRecordContext } from "react-admin";
import { Avatar } from "@mui/material";
import { mergeSx } from "~/helpers";
import { chain, get } from "lodash";
import { forwardRef } from "react";

const sizes = {
  sm: {
    width: "40px",
    height: "40px",
  },
  md: {
    width: "150px",
    height: "150px",
  },
  lg: {
    width: "300px",
    height: "300px",
  },
};

const AvatarField = forwardRef(
  (
    {
      source,
      size = "sm",
      sx,
      link = false,
      hideEmpty = false,
      nameSource = "name",
      ...props
    },
    ref
  ) => {
    const record = useRecordContext();
    const createPath = useCreatePath();
    if (!record) return null;
    const firstInitial = chain(record)
      .get(nameSource)
      .first()
      .capitalize()
      .value();
    let src = get(record, source);
    if (src && typeof src !== "string") {
      src = src.url;
    }
    if (!src && hideEmpty) return null;
    if (link) {
      return (
        <Link
          to={createPath({
            resource: "users",
            id: record.user_id,
            type: "edit",
          })}
          ref={ref}
        >
          <Avatar
            src={src}
            sx={mergeSx(
              sizes[size] || { width: `${size}px`, height: `${size}px` },
              sx
            )}
            {...props}
          >
            {firstInitial}
          </Avatar>
        </Link>
      );
    }
    return (
      <Avatar
        ref={ref}
        src={src}
        sx={mergeSx(
          sizes[size] || { width: `${size}px`, height: `${size}px` },
          sx
        )}
        {...props}
      >
        {firstInitial}
      </Avatar>
    );
  }
);

export default AvatarField;
