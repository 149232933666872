import { Box, Grid } from "@mui/material";
import { IfCanAccess } from "@react-admin/ra-rbac";
import {
  Edit,
  TabbedForm,
  TextInput,
  email,
  useEditContext,
} from "react-admin";
import { SocialsList, Stats, StyledToolBar, WithWatch } from "~/components";
import HasPlatformExtension from "~/components/HasPlatformExtension";
import { ImageTabs } from "~/components/images";
import { AdminUserInput, RecordExtensionInputs } from "~/components/inputs";
import ItinRichTextArea from "~/components/inputs/ItinRichTextArea";
import { useCurrentPlatform } from "~/context";
import { validateUrl } from "~/hooks";
import { ContentInlineEditList } from "~/resources/contents";
import { OperatingHoursList } from "~/resources/operating_hours";
import { LogList } from "../logs/LogList";
import { UrlsList } from "../urls/UrlsList";
import { BasicFields } from "./BasicFields";
import { ListingFoundContent } from "./ListingFoundContent";
import { ReportFilterProvider } from "~/context/ReportFilterContext";
import { TourismoDateRangePicker } from "~/components/TourismoDateRangePicker";
import { ListingOverview } from "./ListingOverView/ListingOverview";

export const ListingEdit = () => {
  const { getTerm } = useCurrentPlatform();
  const listingTerm = getTerm("listing");
  const listingsTerm = getTerm("listing", { plural: true });
  const userTerm = getTerm("user");
  const record = useEditContext();

  return (
    <Edit mutationMode="pessimistic" title=" " redirect={false}>
      <IfCanAccess
        action="edit"
        record={record}
        resource="listings"
        fallback={
          <Grid container padding={2}>
            <Grid item xs={12}>
              <ListingOverview />
            </Grid>
          </Grid>
        }
      >
        <TabbedForm
          variant="standard"
          mode="onChange"
          toolbar={<StyledToolBar saveButtonProps={{ alwaysEnable: true }} />}
        >
          <TabbedForm.Tab label="Overview">
            <ListingOverview />
          </TabbedForm.Tab>
          <TabbedForm.Tab label="Location & Map">
            <BasicFields show />
          </TabbedForm.Tab>
          <TabbedForm.Tab label="content">
            <Box
              display="grid"
              sx={{ width: "100%" }}
              gridTemplateColumns="repeat(12,1fr)"
              gap={2}
            >
              <Box gridColumn={"span 12"}>
                <TextInput
                  title="Subtitle"
                  placeholder="Keep it punchy.."
                  source="subtitle"
                  isRequired={false}
                  fullWidth
                  helperText={`A short tagline that goes beneath the name of the ${listingTerm}. Example: 'More than you imagined!'`}
                />
              </Box>
              <Box gridColumn="span 6">
                <ItinRichTextArea
                  label="Short Description"
                  helperText={`A summary of the full description. Displayed where multiple ${listingsTerm} are shown. Never shown with the full description`}
                  height="300px"
                  placeholder="Compose an epic.."
                  source="short_description"
                  isRequired={false}
                />
              </Box>
              <Box gridColumn="span 6">
                <ItinRichTextArea
                  label="Full Description"
                  helperText={`A full description you would like associated with your ${listingTerm}. This will appear on the ${listingTerm} page for the ${userTerm}.`}
                  height="300px"
                  placeholder="Compose an epic.."
                  source="description"
                  isRequired={false}
                />
              </Box>
            </Box>
            <ContentInlineEditList />
          </TabbedForm.Tab>
          <TabbedForm.Tab label="Contact & Links">
            <Box
              display="grid"
              sx={{ width: "100%" }}
              gridTemplateColumns="repeat(12,1fr)"
              gap={2}
            >
              <Box gridColumn={"span 6"}>
                <AdminUserInput fullWidth />
                <TextInput source="email" validate={email()} fullWidth />
                <TextInput source="phone_number" fullWidth />
              </Box>
              <Box gridColumn={"span 6"}>
                <TextInput source="website" fullWidth validate={validateUrl} />
                <TextInput
                  source="website2"
                  label="Booking Website"
                  fullWidth
                  validate={validateUrl}
                />
              </Box>
              <Box
                gridColumn={"span 12"}
                style={{ paddingLeft: "40px", paddingRight: "40px" }}
              >
                <SocialsList />
              </Box>
            </Box>
          </TabbedForm.Tab>
          <TabbedForm.Tab label="hours">
            <OperatingHoursList />
          </TabbedForm.Tab>
          <TabbedForm.Tab label="images">
            <ImageTabs />
          </TabbedForm.Tab>
          <TabbedForm.Tab label="Magic Content">
            <HasPlatformExtension
              platform_extension="AiExtension"
              fallback={"Upgrade to gain AI superpowers!"}
            >
              <ListingFoundContent />
            </HasPlatformExtension>
          </TabbedForm.Tab>
          <TabbedForm.Tab label="Reports">
            <ReportFilterProvider>
              <TourismoDateRangePicker />
              <Stats category="report" />
            </ReportFilterProvider>
          </TabbedForm.Tab>
          <TabbedForm.Tab label="Activity Log">
            <LogList />
          </TabbedForm.Tab>
          <TabbedForm.Tab label="Extension Settings">
            <WithWatch
              watch={[
                "extension_data.contesting_extension.redeemable_location",
              ]}
            >
              {([isRedeemable]) => {
                return (
                  <RecordExtensionInputs
                    gridItemProps={(config, source) => {
                      return source ===
                        "extension_data.contesting_extension.redeemable_location"
                        ? { xs: 12 }
                        : null;
                    }}
                    filter={([field, config], source) => {
                      const canShow =
                        source !== "extension_data.contesting_extension" ||
                        field === "redeemable_location" ||
                        !!isRedeemable;
                      return canShow;
                    }}
                  />
                );
              }}
            </WithWatch>
            <UrlsList />
          </TabbedForm.Tab>
        </TabbedForm>
      </IfCanAccess>
    </Edit>
  );
};
