import { Grid, TextField as MuiTextField } from "@mui/material";
import {
  BooleanInput,
  Datagrid,
  DateField,
  Edit,
  FormTab,
  Pagination,
  ReferenceManyField,
  TabbedForm,
  TextField,
  TextInput,
  useEditContext,
} from "react-admin";
import { useDebounceValue } from "usehooks-ts";
import { EditDeleteButtons, Stats, StyledToolBar } from "~/components";
import { GridCard } from "~/components/GridCard";
import { EnumField } from "~/components/fields";
import ItinRichTextArea from "~/components/inputs/ItinRichTextArea";
import { RecordCommunicationList } from "../communications/CommunicationList";
import { TagSidebar } from "../taggings";
import { AddSubscribers } from "./AddSubscribers";
import { ReportFilterProvider } from "~/context/ReportFilterContext";
import { TourismoDateRangePicker } from "~/components/TourismoDateRangePicker";

export const NewsletterEdit = () => {
  const [search, setSearch] = useDebounceValue("", 500);
  return (
    <Edit mutationMode="pessimistic" redirect={false}>
      <TabbedForm
        variant="standard"
        toolbar={<StyledToolBar showDelete={false} />}
      >
        <FormTab label="Basic Setup">
          <Grid container>
            <Grid item xs={6}>
              <TextInput source="name" />
              <ItinRichTextArea height="150px" source="description" />
              <BooleanInput source="auto_confirm_subscription" />
              <TextInput source="slug" disabled />
            </Grid>
            <GridCard item xs={6} title="Tags">
              <TagSidebar />
            </GridCard>
          </Grid>
        </FormTab>
        <FormTab label="Subscribers">
          <AddSubscribers />
          <ReferenceManyField
            target="newsletter_id"
            label=""
            reference="newsletter_users"
            filter={{ q: search }}
            pagination={
              <Pagination
                sx={{ width: "calc(100% + 64px)", marginX: "-32px" }}
              />
            }
          >
            <MuiTextField
              label="Search"
              // value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <Datagrid bulkActionButtons={false}>
              <EnumField
                source="status"
                color_options={{
                  pending_confirmation: "primary",
                  confirmed: "success",
                  opted_out: "error",
                }}
              />
              <TextField source="name" label="Name" />
              <TextField source="email" label="Email" />
              <DateField source="updated_at" emptyText="None" />
              <EditDeleteButtons canDelete canEdit={false} />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
        <FormTab label="Communications">
          <RecordCommunicationList />
        </FormTab>
        <FormTab label="Reports">
          <ReportFilterProvider>
            <TourismoDateRangePicker />
            <Stats />
          </ReportFilterProvider>
        </FormTab>

        <FormTab label="Embed Code">
          <FormCode />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export const FormCode = () => {
  const { record } = useEditContext();
  return (
    <pre>
      {`
  <form method="post" url="/ui/subscribe/${record.platform_id}/${record.slug}">
   Your Name: <input id="email" />
   Your Email: <input id="name" />
  </form>
  `}
    </pre>
  );
};
