import Intercom from "@intercom/messenger-js-sdk";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Box,
  AppBar as MuiAppBar,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { useAppLocationState } from "@react-admin/ra-navigation";
import * as Sentry from "@sentry/react";
import { ArrowClockwise, Globe, PencilSimple } from "@phosphor-icons/react";
import {
  Button,
  EditButton,
  ListButton,
  RefreshButton,
  useGetIdentity,
  useGetRecordRepresentation,
  useGetResourceLabel,
  useResourceDefinition,
} from "react-admin";
import { Helmet } from "react-helmet";
import { useCurrentPlatform, useProfileState } from "~/context";
import { ItinLoading } from "../ItinLoading";
import PlatformMenu from "./PlatformMenu";
import { UserMenu } from "./UserMenu";
import { LayoutClasses } from "./layoutClasses";
import { ReportFilterProvider } from "~/context/ReportFilterContext";
import { TourismoDateRangePicker } from "../TourismoDateRangePicker";

const BackButton = (props) => {
  const { view, resource } = props;

  if (!view) return <PlatformMenu />;

  return (
    <ListButton
      variant="outlined"
      resource={resource}
      label="Go Back"
      icon={<ArrowBackIcon />}
    />
  );
};

export const handleVisitSite = (currentPlatform, resource_name, record) => {
  const cms_extension = currentPlatform.platform_extensions.find(
    (pe) => pe.type === "CmsExtension"
  );
  const urlable_resources = ["regions", "listings", "itineraries", "events"];

  if (urlable_resources.includes(resource_name) && record?.id) {
    window.open(record.public_url, "_blank");
    return;
  } else if (urlable_resources.includes(resource_name)) {
    //  console.log(cms_extension?.options[`${single_resource_name}_root_url`]);
    // currently we only store the single record url in the DB, so can't explicitly route to public index page, instead go home.
    window.open(
      cms_extension?.options?.root_url
        ? cms_extension?.options?.root_url
        : `https://${currentPlatform.slug}.itinerator.net/${resource_name}`,
      "_blank"
    );
    return;
  } else {
    window.open(
      cms_extension?.options?.root_url
        ? cms_extension?.options?.root_url
        : `https://${currentPlatform.slug}.itinerator.net/`,
      "_blank"
    );
  }
};

export const AppBar = (props) => {
  const { profileVersion } = useProfileState();
  const { identity } = useGetIdentity();
  const [{ path, values }] = useAppLocationState();
  const [resource, view] = path?.split(".") || [];
  const resource_name = [resource, view]
    .filter((s) => !["list", "edit", "show"].includes(s))
    .filter(Boolean)
    .reverse()
    .join(" ");
  const { currentPlatform, getTerm, isLoading } = useCurrentPlatform();
  const record = values?.record;
  const { hasShow, hasEdit } = useResourceDefinition({ resource });
  const getRecordRepresentation = useGetRecordRepresentation(resource);
  const getResourceLabel = useGetResourceLabel();
  if (!identity) return <ItinLoading />;

  if (currentPlatform && identity && !isLoading) {
    Sentry.setUser({
      email: identity.email,
      username: identity.name,
      platform: currentPlatform.name,
    });
    Intercom({
      app_id: "xmxf7u7n",
      user_id: identity.id,
      name: identity.name,
      email: identity.email,
      created_at: identity.created_at,
      Platform: currentPlatform.name,
      Platform_Name: currentPlatform.name,
      Platform_User_Role: identity.role,
    });
  }

  const name =
    resource === "dashboard"
      ? ""
      : getRecordRepresentation(record) || getResourceLabel(resource, 2);
  const title = ["Itinerator", name].filter(Boolean).join(": ");

  return (
    <MuiAppBar {...props} className={LayoutClasses.appBar}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Toolbar sx={{ gap: 3 }}>
        <BackButton view={view} resource={resource} />

        <Typography
          flex="1"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          overflow="hidden"
          variant="h3"
          color="black"
          {...props}
        >
          {name}
        </Typography>
        <Box flexGrow={1} />
        {view && (
          <Stack
            direction="row"
            spacing={2}
            sx={{ "& .MuiButton-endIcon": { color: "grey.400" } }}
          >
            {view === "show" && hasEdit && (
              <EditButton
                resource={resource}
                record={values?.record}
                endIcon={<PencilSimple />}
                icon={null}
              />
            )}

            {/* {resource === "reports" && (
              <ReportFilterProvider>
                <TourismoDateRangePicker />
              </ReportFilterProvider>
            )} */}
            {/* <RefreshButton
              alignIcon="right"
              // variant="outlined"
              icon={<ArrowClockwise />}
            /> */}
          </Stack>
        )}
        {identity && identity.role == "admin" && (
          <Button
            startIcon={<Globe />}
            label={`View ${
              record
                ? getTerm(resource, {
                    capitalize: true,
                  })
                : "Site"
            }`}
            onClick={() => {
              handleVisitSite(currentPlatform, resource_name, record);
            }}
          />
        )}
        <UserMenu key={profileVersion} />
      </Toolbar>
    </MuiAppBar>
  );
};
