import { useCanAccess } from "@react-admin/ra-rbac";
import { Edit, FormTab, TabbedForm } from "react-admin";
import { StyledToolBar } from "~/components";
import { handleArrayAttributes } from "~/helpers";
import { LogList } from "../logs/LogList";
import {
  EditBasicTab,
  EditDescriptionTab,
  EditEventTimeTab,
  EditImagesTab,
} from "./edit";
import { EventOverview } from "./EventOverview";

const transform = (data, { previousData }) => {
  return handleArrayAttributes(data, previousData, "locations");
};
export const EventEdit = () => {
  const { canAccess: canUseLogs } = useCanAccess({
    resource: "logs",
    action: "read",
  });
  return (
    <Edit mutationMode="pessimistic" redirect={false} transform={transform}>
      <TabbedForm
        redirect={false}
        variant="standard"
        toolbar={<StyledToolBar />}
        defaultValues={{ target_type: "Region" }}
      >
        <FormTab label="overview">
          <EventOverview />
        </FormTab>
        <EditBasicTab />
        <EditEventTimeTab />
        <EditDescriptionTab />
        <EditImagesTab />
        {canUseLogs && (
          <FormTab label="Activity Log">
            <LogList />
          </FormTab>
        )}
      </TabbedForm>
    </Edit>
  );
};
