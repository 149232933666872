import {
  AutocompleteInput,
  Datagrid,
  DateField,
  FunctionField,
  ReferenceManyField,
  SingleFieldList,
  TextField,
} from "react-admin";
import AvatarField from "~/components/AvatarField";
import { EnumField, StatsSummaryField } from "~/components/fields";
import { ToggleList } from "~/components/lists";
import { useCurrentPlatform } from "~/context";
import { useRoles } from "~/hooks";
import { UserCard } from "./UserCard";

const filters = (roles) => {
  if (!roles?.length) return undefined;
  return [
    <AutocompleteInput
      label="By Role"
      source="role"
      choices={roles}
      emptyText="By Role"
    />,
  ];
};

export const UserList = () => {
  const { data: roles } = useRoles();

  if (!roles) return null;
  return <ToggleList filters={filters(roles)} modes={MODES} />;
};
const ListData = () => {
  const { loading: platformLoading, currentPlatform } = useCurrentPlatform();
  if (platformLoading) return null;
  return (
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <AvatarField source="avatar" label={""} />
      <TextField source="name" fullWidth />
      <TextField source="email" />
      <EnumField
        source="role"
        color_options={{
          public_user: "primary",
          admin_user: "success",
          super_admin: "error",
        }}
      />
      <ReferenceManyField
        reference="platform_users"
        target="user_id"
        filter={{ platform_id: currentPlatform.id }}
        label="Platform Role"
      >
        <SingleFieldList linkType={false}>
          <EnumField
            source="role"
            color_options={{
              user: "primary",
              collaborator: "success",
              viewer: "warning",
              admin: "error",
            }}
          />
        </SingleFieldList>
      </ReferenceManyField>
      <ReferenceManyField
        reference="platform_users"
        target="user_id"
        filter={{ platform_id: currentPlatform.id }}
        label="Last Login"
      >
        <SingleFieldList linkType={false}>
          <DateField source="last_log_in" />
        </SingleFieldList>
      </ReferenceManyField>

      <ReferenceManyField
        reference="platform_users"
        target="user_id"
        filter={{ platform_id: currentPlatform.id }}
        label="Points"
      >
        <SingleFieldList linkType={false}>
          <FunctionField
            label="Points"
            render={(record) => `${record.points_remaining}/${record.points}`}
          />
        </SingleFieldList>
      </ReferenceManyField>
      <StatsSummaryField />
    </Datagrid>
  );
};

const MODES = [
  { name: "Grid", view: <UserCard /> },
  { name: "List", view: <ListData /> },
];
