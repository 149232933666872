import { Box, Drawer, Typography } from "@mui/material";
import {
  CreateBase,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
  useCreateSuggestionContext,
  useNotify,
} from "react-admin";
import { useCurrentPlatform } from "~/context";

export const ContentTypeForm = ({ class_type }) => {
  const { filter, onCancel, onCreate } = useCreateSuggestionContext();
  const default_val = filter.replace("Create ", "");
  const { currentPlatform } = useCurrentPlatform();
  const notify = useNotify();

  const onSuccess = (data) => {
    notify("ra.notification.created", {
      type: "info",
      messageArgs: { smart_count: 1 },
    });
    onCreate(data);
  };

  return (
    <Drawer
      anchor="right"
      open={true}
      onClose={onCancel}
      PaperProps={{
        sx: {
          backgroundColor: "white",
          "& .MuiToolbar-root": {
            paddingX: 0,
          },
        },
      }}
    >
      <Box
        sx={{
          width: "50vw",
          padding: 4,
          backgroundColor: "white",
        }}
      >
        <CreateBase
          resource="content_types"
          redirect={false}
          mutationOptions={{ onSuccess }}
        >
          <SimpleForm
            defaultValues={{
              platform_id: currentPlatform.id,
              class_type,
            }}
          >
            <Typography variant="h3" marginBottom={3}>
              Create Content Type
            </Typography>
            <TextInput
              source="name"
              defaultValue={default_val}
              validate={required()}
            />
            <SelectInput
              label="Media Type"
              source="type"
              choices={[
                { id: 0, name: "Text" },
                { id: 1, name: "Media" },
                { id: 2, name: "Media & Text" },
                { id: 3, name: "Editor" },
              ]}
              validate={required()}
            />
          </SimpleForm>
        </CreateBase>
      </Box>
    </Drawer>
  );
};
