import { useRecordContext } from "react-admin";
import { Marker as BaseMarker } from "../Marker";
import { useHandleClick } from "./helpers";
import { get } from "lodash";
import WKT from "terraformer-wkt-parser";

export const Marker = ({ isActive, source, ...props }) => {
  const { name, ...record } = useRecordContext(props);
  const lonlat = get(record, source);
  const onClick = useHandleClick(props);
  if (!lonlat) return null;
  const [lng, lat] = WKT.parse(lonlat).coordinates;
  return (
    <BaseMarker
      color={isActive ? "#EA4335" : "#424242"}
      position={{ lat, lng }}
      label={
        name
          ? {
              text: name,
              fontSize: "12px",
              fontWeight: "normal",
              color: "black",
              strokeColor: "white",
              strokeWeight: 1,
            }
          : undefined
      }
      {...props}
      onClick={onClick}
    />
  );
};
