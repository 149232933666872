import { List } from "react-admin";
import { Empty } from "~/components";
import { ImageGrid } from "~/components/lists/ImageGrid";
import { useCurrentPlatform } from "~/context";
import { eventCardData } from "./eventCardData";
import { GridCard } from "~/components/GridCard";

export const UpcomingEvents = () => {
  const { getTerm } = useCurrentPlatform();
  const eventTerm = getTerm("event");
  //      sort={{ field: "start", order: "ASC" }}
  //      filter={{ event_times: { start_gte: new Date().toISOString() } }}

  return (
    <List
      actions={false}
      pagination={false}
      resource={"events"}
      perPage={4}
      empty={
        <GridCard>
          <Empty
            title={`You have no upcoming ${eventTerm}s :(`}
            buttonLabel="Create your first event"
            // subtitle={`Don't let the haters get to you, showcase your place!`}
          />
        </GridCard>
      }
    >
      <ImageGrid cardData={eventCardData} />
    </List>
  );
};
