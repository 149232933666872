import { LineChart } from "@mui/x-charts";
import dayjs from "dayjs";

export const DateLineChart = ({ data, comparisonData }) => {
  // Extract the date range from the current data
  const xAxisData = data.map((item) => new Date(item.date));

  // Create a map of dates to counts for easy lookup
  const dataMap = new Map(
    data.map((item) => [new Date(item.date).getTime(), item.count])
  );
  const comparisonDataMap = comparisonData
    ? new Map(
        comparisonData.map((item) => [
          new Date(item.date).getTime(),
          item.count,
        ])
      )
    : new Map();

  // Align series data with the xAxisData
  const seriesData = xAxisData.map(
    (date) => dataMap.get(date.getTime()) ?? null
  );

  // Shift the comparisonData to start from the beginning of the xAxisData
  const comparisonSeriesData = comparisonData
    ? xAxisData.map((date, index) => {
        const comparisonDate = comparisonData[index]
          ? new Date(comparisonData[index].date).getTime()
          : null;
        return comparisonDate ? comparisonDataMap.get(comparisonDate) : null;
      })
    : null;

  return (
    <div>
      <LineChart
        xAxis={[
          {
            label: "Date",
            data: xAxisData,
            tickInterval: xAxisData,
            scaleType: "time",
            valueFormatter: (date) => dayjs(date).format("MMM D"),
          },
        ]}
        yAxis={[{ label: "Count" }]}
        series={[
          { label: "Current", data: seriesData, color: "#6d88f4", area: true },
          comparisonData && {
            label: "Previous",
            data: comparisonSeriesData,
            color: "#263a96",
          },
        ].filter(Boolean)}
        height={300}
        slotProps={{
          legend: {
            direction: "row",
            labelStyle: {
              fontSize: 14,
            },
            position: { vertical: "top", horizontal: "right" },
            padding: 0,
          },
        }}
      />
    </div>
  );
};
