import { ExpandMore } from "@mui/icons-material";
import { Button, Menu, MenuItem, Typography } from "@mui/material";
import { sortBy } from "lodash";
import { useState } from "react";
import { useGetList, useGetMany, useGetOne, useRefresh } from "react-admin";
import { useQueryClient } from "react-query";
import { useCurrentPlatform } from "~/context";
import { useEditorPlatformUsers } from "~/hooks";

const PlatformMenu = () => {
  const queryClient = useQueryClient();
  const { currentPlatform, loading, setPlatform } = useCurrentPlatform();
  const { data: platforms, isLoading } = usePlatformList();
  const refresh = useRefresh();
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (isLoading || loading) return null;
  if (platforms.length <= 1)
    return (
      <Typography
        variant="h4"
        sx={{ color: "grey.800", textTransform: "capitalize" }}
      >
        {currentPlatform?.name}
      </Typography>
    );
  return (
    <>
      <Button onClick={handleClick} variant="dropdown" endIcon={<ExpandMore />}>
        <Typography variant="h4">{`${currentPlatform?.name}`}</Typography>
      </Button>
      {platforms.length > 1 && (
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          {sortBy(platforms, ["name"]).map(({ id, name, active }) => (
            <MenuItem
              key={id}
              value={id}
              onClick={() => {
                setPlatform(id).then(() => {
                  handleClose();
                  refresh();
                });
                queryClient.invalidateQueries({ queryKey: ["analytics"] });
              }}
              selected={id === currentPlatform?.id}
              sx={{ textTransform: "capitalize" }}
            >
              {name}
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  );
};

const usePlatformList = () => {
  const { data: currentUser, isLoading: isUserLoading } = useGetOne("users", {
    id: "current",
  });
  const isSuperAdmin = currentUser?.role === "super_admin";
  // If the currentUser is not a super admin the list of platforms should only be the ones they are editors of.

  // Get the list of editor_platform_users for the current user unless they are a super admin.
  const { data: platform_users = [], isLoading: platformUsersIsLoading } =
    useEditorPlatformUsers({
      enabled: !isUserLoading && !isSuperAdmin,
    });

  // Get the platforms from the platform_users list unless they are a super admin.
  const { data: editor_platforms = [], isLoading } = useGetMany(
    "platforms",
    {
      ids: platform_users.map((p) => p.platform_id),
    },
    { enabled: !platformUsersIsLoading && !isSuperAdmin }
  );

  // If they are a super admin get all the platforms.
  const { data: all_platforms = [], isLoading: allPlatformsIsLoading } =
    useGetList(
      "platforms",
      { pagination: { page: 1, perPage: 100 } },
      { enabled: !platformUsersIsLoading && isSuperAdmin }
    );
  return {
    data: isSuperAdmin ? all_platforms : editor_platforms,
    isLoading:
      isUserLoading ||
      platformUsersIsLoading ||
      isLoading ||
      allPlatformsIsLoading,
  };
};

export default PlatformMenu;
