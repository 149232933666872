import { Grid } from "@mui/material";
import { useRecordContext } from "react-admin";
import { Stats } from "~/components";
import { ClaimRecord } from "~/components/buttons/ClaimRecord";
import { GridCard } from "~/components/GridCard";
import HasPlatformExtension from "~/components/HasPlatformExtension";
import { useCurrentPlatform } from "~/context";
import { TagSidebar } from "~/resources/taggings";
import { SubscriptionOptions } from "~/stripe/SubscriptionOptions";
import { ListingChallenges } from "./ListingChallenges";
import { ListingHero } from "./ListingHero";
import { ListingItineraries } from "./ListingItineraries";
import { ListingMap } from "./ListingMap";

export const ListingOverview = (props) => {
  const record = useRecordContext(props);
  const { getTerm } = useCurrentPlatform();
  const listingTerm = getTerm("listing");
  const tagTerm = getTerm("tag", {
    plural: true,
    capitalize: true,
  });
  const challsTerm = getTerm("challenge", { plural: true, capitalize: true });
  return (
    <Grid container spacing={2}>
      <Grid container item xs={12} md={8} rowGap={2}>
        <GridCard item xs={12}>
          <ListingHero record={record} />
          <SubscriptionOptions
            cta={`Upgrade this ${listingTerm} to take advantage of additional features.`}
          />
        </GridCard>

        <HasPlatformExtension platform_extension="AnalyticsExtension">
          <Stats />
        </HasPlatformExtension>
      </Grid>
      <Grid container item xs={12} md={4} rowGap={2}>
        <GridCard title="Location">
          <ListingMap record={record} />
        </GridCard>
        <ClaimRecord />
        <GridCard title={tagTerm}>
          <TagSidebar />
        </GridCard>

        <HasPlatformExtension platform_extension="Contesting">
          {record.challenge_ids.length > 0 && (
            <GridCard title={challsTerm}>
              <ListingChallenges />
            </GridCard>
          )}
        </HasPlatformExtension>
        {record.itinerary_ids.length > 0 && (
          <GridCard title="Itineraries">
            <ListingItineraries />
          </GridCard>
        )}
      </Grid>
    </Grid>
  );
};
