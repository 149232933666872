import { ToggleButton, ToggleButtonGroup } from "@mui/lab";
import { Box, Card, Grid, GridProps, Typography } from "@mui/material";
import { SparkLineChart } from "@mui/x-charts";
import { ArrowDown, ArrowUp, ChartLine, ChartPie, Table } from "@phosphor-icons/react";
import { get, map } from "lodash";
import { useState } from 'react';
import { SimpleMapList } from "~/components";
import { MapDataProvider } from "~/components/maps/MapDataProvider";
import { abbreviateNumber } from "~/helpers/abbreviateNumber";
import { showDate } from "~/helpers/showDate";
import { StatSummaryCard } from "~/resources/types";
import { DateLineChart } from "./DateLineChart";
import { ReportDataGrid } from "./ReportDataGrid";
import { ReportPie } from "./ReportPie";
import { DEFAULT_STATS_ICONS } from "./stats_constants";

export const ToggleTablePie = ({ data, name }) => {
  const [view, setView] = useState('table');

  const handleViewChange = (event, newView) => {
    if (newView !== null) {
      setView(newView);
    }
  };

  return (
    <Box display="flex" flexDirection="column" width="100%">
    <Box display="flex" justifyContent="flex-end" mb={2}>
      <ToggleButtonGroup
        value={view}
        exclusive
        onChange={handleViewChange}
        size="small"
      >
        <ToggleButton value="table"><Table /></ToggleButton>
        <ToggleButton value="pie"><ChartPie /></ToggleButton>
      </ToggleButtonGroup>
    </Box>
    
    <Box>
      {view === 'table' && <ReportDataGrid data={data} name={name} />}
      {view === 'pie' && <ReportPie data={data} />}
    </Box>
  </Box>
  );
};

type Props = GridProps & {
  cards?: StatSummaryCard[];
  gridItemProps?: GridProps;
};

const descriptionStyle = {
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitLineClamp: 4,
  maxHeight: `calc(1.2em * 4)`,
  lineHeight: '1.2em',
};


export const SummaryCards = ({ cards, ...props }: Props) => {
  if (!cards?.length) return null;

  return (
    <Grid container spacing={2} {...props}>
      {cards.map((card) => {
        if (card.value==0) return null;
        return (
          <SummaryCard key={`${card.name}-${card.stat_type}`} card={card} />
        );
      })}
    </Grid>
  );
};
export const HeatMapCard = ({map_points}) => {
  return (
    <Box height={"550px"} width={"100%"}>
        <MapDataProvider>
          <SimpleMapList heatmapData={map_points} />
        </MapDataProvider>
    </Box>
  );
};


export const SummaryCard = ({ card }: { card: StatSummaryCard }) => {
  const { name, value, sparkline, table, linechart, pie, xs, comparison_data, comparison_value, map_points, description, stat_type } = card;
  const percentage_of_change = comparison_value ? Math.round((value/comparison_value-1)*100) : null;
  const Icon = get(DEFAULT_STATS_ICONS, stat_type, ChartLine);

  // This view attempts to intelligently render a chart card right from a basic number count, all the way to a full featured chart  
  return (
    <Grid item xs={card.xs ? card.xs : "auto"}>
      {/* @ts-expect-error */}
      <Grid container as={Card} variant={"outlined"}>
        <Grid item xs={12}>
        <Typography variant={(linechart || table) ? "h4" : "h3"}>
        <Icon size={28} style={{ verticalAlign: 'middle',marginTop:'-2px' }} />
        {value && abbreviateNumber(value)} {(linechart || table) && name}</Typography>
        <Typography>{!(linechart || table) && name}</Typography>
        <Typography sx={descriptionStyle}>{description}</Typography>


        {comparison_value && <Typography color={percentage_of_change > 0 ? '#00aa00' : '#aa0000'}> 
        {percentage_of_change > 0 ? <ArrowUp /> : <ArrowDown />}
        {percentage_of_change}%
        from {comparison_value && abbreviateNumber(comparison_value)}
        </Typography>
        }
    
          {linechart && (
          <DateLineChart data={linechart} comparisonData={comparison_data} height={200}  sx={{ position: "absolute", left: 0, right: 0 }} />
          )}
    
    
          {sparkline && (
            <SparkLineChart
              area
              data={map(sparkline, "count")}
              colors={["#6d88f4"]}
               curve="natural"
              // @ts-expect-error valueFormatter has incorrect typing for the function args
              valueFormatter={(val, { dataIndex }) => {
                const date_string = sparkline[dataIndex]?.date;
                const date = date_string && showDate(date_string);
                return [date, val].filter(Boolean).join(" - ");
              }}
              height={100}
              showTooltip
              showHighlight
              sx={{ position: "absolute", left: 0, right: 0 }}
            />
          )}
        
        {map_points && <HeatMapCard map_points={map_points} />}

       {table && <ToggleTablePie data={table} name={name} />}
       </Grid>
       </Grid>
    </Grid>
  );
};


