import { Box, Typography } from "@mui/material";
import { useRecordContext } from "react-admin";
import { SimpleMapList } from "~/components";
import { MapDataProvider } from "~/components/maps/MapDataProvider";
import { useStats } from "~/hooks";
export const ListingMap = ({ record }) => {
  const { data, isLoading } = useStats({ category: "check_ins_map" });
  return (
    <Box height={"350px"}>
      {!record.lonlat && <Empty />}
      {record.lonlat && (
        <MapDataProvider>
          <SimpleMapList heatmapData={!isLoading && data.points} />
        </MapDataProvider>
      )}
    </Box>
  );
};
const Empty = () => <Typography>No listing location set.</Typography>;
