import {
  Pagination,
  ReferenceManyField,
  TextField,
  useEditContext,
  useRecordContext,
} from "react-admin";
import { TaskForm } from "./TaskForm";
import { DrawerDatagrid } from "~/components/drawer";
import { useCustomQuery } from "~/hooks";
import { Typography } from "@mui/material";
import { PolymorphicReferenceField } from "~/components/fields";
import { useCurrentPlatform } from "~/context";

export const TasksList = () => {
  const { record } = useEditContext();
  const { getTerm } = useCurrentPlatform();
  const pointsCapTerm = getTerm("point", {
    plural: true,
    capitalize: true,
  });
  const userTerm = getTerm("user", {
    plural: false,
    capitalize: true,
  });
  return (
    <ReferenceManyField
      target="challenge_id"
      reference="tasks"
      pagination={<Pagination />}
    >
      <DrawerDatagrid Form={<TaskForm challenge={record} />}>
        <TextField source="type" />
        <TextField source="weight" label={`Completion ${pointsCapTerm}`} />
        <PolymorphicReferenceField
          reference_source="target_type"
          source="target_id"
        />
        <TaskCount
          source="id"
          label={`${userTerm} interactions`}
          sortable={false}
        />
        {/* <ReferenceManyField source="form_id" reference="form"/> */}
      </DrawerDatagrid>
    </ReferenceManyField>
  );
};
const TaskCount = () => {
  const record = useRecordContext();
  const count = useCustomQuery(`tasks/${record.id}/task_count`);
  return <Typography>{count.data}</Typography>;
};
