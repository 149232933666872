import { Grid } from "@mui/material";
import {
  BooleanInput,
  FormTab,
  ReferenceInput,
  ReferenceManyField,
  SelectInput,
  TabbedForm,
  TextInput,
  useRecordContext,
} from "react-admin";
import { useWatch } from "react-hook-form";
import { Stats } from "~/components";
import { AdminUserInput, EnumChoiceInput } from "~/components/inputs";
import ItinMarkdownInput from "~/components/ItinMarkdownInput";
import { FormFieldList } from "../form_fields";

export const FormForm = () => {
  const record = useRecordContext();

  return (
    <TabbedForm variant="standard" mode="onChange">
      <FormTab label="Details">
        <Grid container columnSpacing={3}>
          <Grid item xs={12} md={6}>
            <TextInput source="name" fullWidth />
          </Grid>
          <Grid item xs={12} md={6}>
            <EnumChoiceInput source="status" isRadio />
          </Grid>
          <Grid item xs={12} md={6}>
            <EnumChoiceInput isRadio source="approval_type" />
          </Grid>
          <Grid item xs={12} md={12}>
            <ItinMarkdownInput
              title="Description"
              source="description"
              isRequired={false}
              titleVariant="body"
            />
          </Grid>
        </Grid>
      </FormTab>
      {record?.id && (
        <FormTab label="Fields">
          <ReferenceManyField reference="form_fields" target="form_id">
            <FormFieldList />
          </ReferenceManyField>
        </FormTab>
      )}
      {record?.id && (
        <FormTab label="Notifications">
          <FormNotificationsTab record={record} />
        </FormTab>
      )}
      <FormTab label="Report">
        <Stats category="report" />
      </FormTab>
    </TabbedForm>
  );
};

export const FormNotificationsTab = ({ record }) => {
  const [notify_sender_on_receipt, notify_sender_on_approval] = useWatch({
    name: ["notify_sender_on_receipt", "notify_sender_on_approval"],
  });

  return (
    <Grid container columnSpacing={3}>
      <Grid item xs={12} md={6}>
        <AdminUserInput />
        <BooleanInput source="notify_owner_on_receipt" fullWidth />
      </Grid>
      <Grid item xs={12} md={6}>
        {(notify_sender_on_approval || notify_sender_on_receipt) && (
          <ReferenceInput
            source="notify_email_field_id"
            reference="form_fields"
            filter={{ type: 0 }}
          >
            <SelectInput
              label="Select Email Field"
              helperText="Select which form field the user will enter their email address into"
              fullWidth
              shouldUnregister
            />
          </ReferenceInput>
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        <BooleanInput source="notify_sender_on_receipt" fullWidth />
        {notify_sender_on_receipt && (
          <>
            <TextInput
              source="notify_received_subject"
              label="Subject"
              fullWidth
              placeholder={`Submission to ${record.name} Received!`}
            />
            <ItinMarkdownInput
              title="Received Message"
              source="notify_received_body"
              fullWidth
              isRequired={false}
              placeholder={`We have received your submission for ${record.name}. Thank you!`}
            />
          </>
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        <BooleanInput source="notify_sender_on_approval" fullWidth />
        {notify_sender_on_approval && (
          <>
            <TextInput
              source="notify_approved_subject"
              label="Subject"
              fullWidth
              placeholder={`Submission to ${record.name} Approved!`}
            />
            <ItinMarkdownInput
              title="Approval Message"
              source="notify_approved_body"
              fullWidth
              isRequired={false}
              placeholder={`Your submission for ${record.name} has been approved. Thank you!`}
            />
          </>
        )}
      </Grid>
    </Grid>
  );
};
