import { PieChart } from "@mui/x-charts";
import { titleize } from "~/helpers";

export const ReportPie = ({ data }) => {
  if (!data || !data.length) return null;
  const parsed_data = data.map((item) => ({
    label: titleize(item.value),
    value: item.count,
  }));
  return (
    <div style={{ width: "100%", maxHeight: "750px" }}>
      <PieChart
        series={[
          {
            data: parsed_data,
          },
        ]}
        height={250}
        slotProps={{
          legend: {
            direction: "column",
            labelStyle: {
              fontSize: 12,
              fontWeight: 500,
            },
            position: { vertical: "top", horizontal: "right" },
            padding: 10,
          },
        }}
      />
    </div>
  );
};
