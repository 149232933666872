import {
  Button,
  Card,
  Checkbox,
  FormHelperText,
  Grid,
  InputLabel,
  TextField as MuiTextField,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { Plus } from "@phosphor-icons/react";
import { useState } from "react";
import {
  Confirm,
  email as emailValidator,
  useCreate,
  useNotify,
  useRecordContext,
} from "react-admin";
import { useQueryClient } from "react-query";
import { useBoolean } from "usehooks-ts";
import { UploadButton } from "~/components";
import { useCustomMutation } from "~/hooks";

export const AddSubscribers = () => {
  const [create] = useCreate();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [status, setStatus] = useState(0);
  const { value: confirmOpen, setFalse, setTrue } = useBoolean(false);
  const record = useRecordContext();
  const notify = useNotify();
  const [error, onError] = useState();
  // console.log("❗️ - AddSubscribers - error:", error?.message);

  const handleAutoConfirm = ({ target: { checked } }) => {
    if (checked) {
      setTrue();
    } else {
      setFalse();
      setStatus(0);
    }
  };

  const handleCreate = () => {
    if (!name) return onError({ message: "Name is required." });
    const invalidEmail = emailValidator("Email not valid.")(email);
    if (invalidEmail) return onError(invalidEmail);
    create(
      "newsletter_users",
      {
        data: {
          subscribe_name: name,
          subscribe_email: email,
          newsletter_id: record.id,
          subscribe_type: "manual",
          status,
        },
      },
      {
        onError,
        onSuccess: (d) => {
          // setEmail("");
          // setName("");
          notify(`${email} added to newsletter`, { type: "success" });
        },
      }
    );
  };
  return (
    <Card variant="outlined" sx={{ flexDirection: "column", mb: 4 }}>
      <Typography variant="h5">Add Subscribers</Typography>
      <Grid container gap={2} alignItems={"flex-end"}>
        <Grid item>
          <MuiTextField
            label="Name"
            autoComplete="off"
            value={name}
            error={!!error}
            onChange={({ target }) => {
              setName(target.value);
              onError();
            }}
            required
          />
        </Grid>
        <Grid item>
          <MuiTextField
            label="Email"
            autoComplete="off"
            error={!!error}
            value={email}
            onChange={({ target }) => {
              setEmail(target.value);
              onError();
            }}
            onKeyDown={(e) => e.key === "Enter" && handleCreate()}
            required
          />
        </Grid>
        <Grid item>
          <Stack direction="row" spacing={2} mb={0.5} alignItems={"center"}>
            <Button
              color="primary"
              variant="contained"
              startIcon={<Plus />}
              onClick={handleCreate}
            >
              Add
            </Button>
            <Typography variant="button">or</Typography>
            <UploadCsvButton status={status} />
            <InputLabel sx={{ display: "flex", gap: 1 }}>
              <Checkbox checked={!!status} onChange={handleAutoConfirm} />
              Auto Confirm?
            </InputLabel>
          </Stack>
        </Grid>
      </Grid>
      <FormHelperText error={!!error}>
        {error?.message ||
          "Enter a Name and Email to manually add a subscriber."}
      </FormHelperText>
      <FormHelperText error={!!error}>
        {"Select Auto Confirm to skip sending a confirmation email."}
      </FormHelperText>
      <Confirm
        isOpen={confirmOpen}
        title="Automatically mark new subscribers as confirmed?"
        content="Only add people who have given you permission."
        onClose={setFalse}
        onConfirm={() => {
          setStatus(1);
          setFalse();
        }}
      />
    </Card>
  );
};

const UploadCsvButton = ({ status }) => {
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useCustomMutation();
  const notify = useNotify();
  const record = useRecordContext();

  return (
    <Tooltip title="csv requires 'name' and 'email' columns">
      <UploadButton
        loading={isLoading}
        inputProps={{ accept: ".csv" }}
        onChange={({ target }) => {
          var data = new FormData();
          data.append("file", target.files[0]);
          data.append("status", status);
          mutate(
            {
              resource: "newsletters",
              custom_url: "csv",
              id: record.id,
              data,
            },
            {
              onSuccess: ({ data }) => {
                notify("Subscribers added");
                queryClient.invalidateQueries([
                  "newsletter_users",
                  "getManyReference",
                ]);
              },
              onError: (e) => notify(e.message, { type: "error" }),
            }
          );
        }}
      >
        Upload CSV
      </UploadButton>
    </Tooltip>
  );
};
