import { useDefineAppLocation } from "@react-admin/ra-navigation";
import { Show, TabbedShowLayout } from "react-admin";
import { Stats } from "~/components";
import { TourismoDateRangePicker } from "~/components/TourismoDateRangePicker";
import { useCurrentPlatform } from "~/context";
import { ReportFilterProvider } from "~/context/ReportFilterContext";

export const ContentReport = () => {
  useDefineAppLocation("reports.content");
  const { currentPlatform, isLoading } = useCurrentPlatform();
  if (isLoading) return "";
  return (
    <ReportFilterProvider>
      <TourismoDateRangePicker />
      <Show resource="platforms" id={currentPlatform?.id} actions={false}>
        <TabbedShowLayout>
          <TabbedShowLayout.Tab label="Contents">
            <Stats category="content" />
          </TabbedShowLayout.Tab>
        </TabbedShowLayout>
      </Show>
    </ReportFilterProvider>
  );
};
