import { Box, Typography } from "@mui/material";
import pluralize from "pluralize";
import { Labeled, NumberInput } from "react-admin";
import { useFormContext } from "react-hook-form";
import { EnumChoiceInput } from "~/components/inputs";

export const TaskIntervalInputs = () => {
  const { watch, getValues } = useFormContext();
  const [type] = watch(["type", "task_interval_count"]);
  if (type !== "RepeatTask") return null;
  return (
    <Labeled
      label="Users Can Repeat Task Every"
      sx={{ "& .RaLabeled-label": { mb: 0 } }}
    >
      <Box display="flex" gap={2} alignItems={"center"}>
        <NumberInput
          source="task_interval_count"
          defaultValue={1}
          label={false}
          sx={{ width: "1" }}
        />
        <EnumChoiceInput
          source="task_interval_type"
          defaultValue="days"
          label={false}
          onChoices={(choices) =>
            choices.map((choice) => ({
              ...choice,
              name: pluralize(choice.name, getValues("task_interval_count")),
            }))
          }
        />
      </Box>
    </Labeled>
  );
};
