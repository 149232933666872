import { insertOrUpdateBlock } from "@blocknote/core";
import { createReactBlockSpec } from "@blocknote/react";
import { Button } from "@mantine/core";
import { Image } from "@phosphor-icons/react";
import { useBoolean } from "usehooks-ts";
import { BlockEditorSchema } from "../BlockInput";
import { ImagePreview } from "./ImagePreview";
import { ImageSelectModal } from "./ImageSelectModal";
import { IMAGE_CONFIG } from "./config";

export const BlockNoteImage = createReactBlockSpec(IMAGE_CONFIG, {
  toExternalHTML: ({ block }) => {
    const { url, previewWidth, textAlignment, fullWidth } = block.props;
    return (
      <div style={{ textAlign: textAlignment }}>
        <img
          style={{ width: fullWidth ? "100%" : previewWidth, maxWidth: "100%" }}
          src={url}
        />
      </div>
    );
  },
  render: ({ block, editor }) => {
    const {
      props: { url },
    } = block;
    const { value, setTrue, setFalse } = useBoolean(!url);
    return (
      <div>
        {!url ? (
          <>
            <Button
              variant="filled"
              onMouseDown={(e) => e.preventDefault()}
              onClick={setTrue}
            >
              Select Image
            </Button>
            <ImageSelectModal block={block} opened={value} onClose={setFalse} />
          </>
        ) : (
          <ImagePreview block={block} editor={editor} />
        )}
      </div>
    );
  },
});

export const insertBlockNoteImage = (editor: BlockEditorSchema) => ({
  title: "Image",
  onItemClick: () => {
    insertOrUpdateBlock(editor, { type: "image" });
  },
  group: "Media",
  icon: <Image />,
  subtext: "Add an image to your content",
});
