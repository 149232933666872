import pluralize from "pluralize";
import { stringify } from "query-string";
import { fetchUtils } from "react-admin";

const apiUrl = "/api/admin";
const httpClient = fetchUtils.fetchJson;

const getFieldResource = (resource) => {
  const custom_fields = {
    reels: "itineraries",
    media: "active_storage_attachments",
  };
  return custom_fields[resource] || resource;
};
export const dataProvider = {
  getCompletion: async (params) => {
    return httpClient(
      `${apiUrl}/${params.meta["resource"]}/${params.meta["id"]}/completions?prompt=${params.prompt}`
    ).then(({ json }) => {
      if (json.success === false) throw new Error(json.message);
      return { data: json.data.choices[0].message.content };
    });
  },
  getList: async (resource, params) => {
    const { page, perPage } = params.pagination || {};
    const { field, order } = params?.sort || {};
    const query = {
      field: `${getFieldResource(resource)}.${field}`,
      order: order,
      limit: perPage,
      offset: (page - 1) * perPage,
      filter: JSON.stringify(params.filter),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => {
      return {
        data: json,
        total: parseInt(headers.get("content-range")?.split("/")?.pop(), 10),
      };
    });
  },

  getOne: async (resource, params) => {
    return httpClient(`${apiUrl}/${resource}/${params.id || "new"}`).then(
      ({ json }) => {
        return { data: json };
      }
    );
  },

  getMany: async (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    return httpClient(url).then(({ json }) => {
      return { data: json };
    });
  },

  getManyReference: async (resource, params) => {
    const { page, perPage } = params.pagination;

    const { field, order } = params.sort;
    const query = {
      field: `${getFieldResource(resource)}.${field}`,
      order: order,
      limit: perPage,
      offset: (page - 1) * perPage,
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id || null,
      }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => {
      return {
        data: json,
        total: parseInt(headers.get("content-range").split("/").pop(), 10),
      };
    });
  },

  create: async (resource, params) => {
    return httpClient(`${apiUrl}/${resource}`, {
      method: "POST",
      body: JSON.stringify({ [pluralize(resource, 1)]: params.data }),
    }).then(({ json }) => {
      if (json.success === false) throw new Error(json.message);
      return {
        data: json,
      };
    });
  },

  update: async (resource, { id, custom_url, data }) => {
    return httpClient(`${apiUrl}/${resource}/${id}${custom_url || ""}`, {
      method: "PUT",
      body: JSON.stringify({ [pluralize(resource, 1)]: data }),
    }).then(({ json }) => {
      if (json.success === false) throw new Error(json.message);
      return { data: json };
    });
  },

  updateMany: async (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: "PUT",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },

  updateManyUnique: async (resource, params) => {
    const { data } = params;
    return httpClient(`${apiUrl}/${resource}/update_many_unique`, {
      method: "PUT",
      body: JSON.stringify({ data: data }),
    }).then(({ json }) => ({ data: json }));
  },

  delete: async (resource, params) => {
    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "DELETE",
    }).then(({ json }) => ({ data: json }));
  },

  deleteMany: async (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${apiUrl}/${resource}/delete_many?${stringify(query)}`, {
      method: "DELETE",
    }).then(({ json }) => ({ data: json }));
  },
  updateUserProfile: async () => {
    //updates the userProfile localstorage data when the current user updates their own profile.
    return httpClient(`${apiUrl}/logged_in`, {
      withCredentials: true,
    }).then(({ json }) => {
      const { user: data } = json;
      localStorage.setItem("userProfile", JSON.stringify(data));
      return Promise.resolve({ data });
    });
  },
  getCustom: async (url, params) => {
    return await httpClient(
      `${apiUrl}/${url}?${stringify(params, { arrayFormat: "bracket" })}`,
      {
        withCredentials: true,
      }
    ).then(({ json }) => Promise.resolve({ data: json }));
  },
  putCustom: async (url, params, data) => {
    return await httpClient(`${apiUrl}/${url}?${stringify(params)}`, {
      method: "PUT",
      withCredentials: true,
      body: data,
    }).then(({ json }) => {
      if (json.success === false) throw new Error(json.message);
      return { data: json };
    });
  },

  getTree: async (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    const url = `${apiUrl}/${resource}/tree?${stringify(query)}`;
    return httpClient(url).then(({ json }) => {
      return { data: json };
    });
  },

  joinPlatform: async (platform) => {
    return httpClient(`${apiUrl}/platforms/${platform.id}/join`).then(
      ({ json }) => {
        return { data: json };
      }
    );
  },

  recordCommunicate: async (resource, record, action) => {
    return httpClient(`${apiUrl}/${resource}/${record.id}/${action}`, {
      method: "PUT",
      withCredentials: true,
    }).then(({ json }) => {
      return { data: json };
    });
  },

  // subscribe: (topic, callback) => {
  //   const [publish] = usePublish();
  //   publish(topic, event);
  // },
};
