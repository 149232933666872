import { useHasPlatformExtension } from "../HasPlatformExtension";
import { OptionsInputs } from "./OptionsInputs";
import { chain } from "lodash";

export const RecordExtensionInputs = ({ filter, ...props }) => {
  const hasExtension = useHasPlatformExtension();
  return (
    <OptionsInputs
      source="extension_data"
      {...props}
      filter={(input, source) => {
        const [field, config] = input;
        const extension_name = chain(field).camelCase().upperFirst().value();
        if (source === "extension_data" && !hasExtension(extension_name))
          return false;
        return filter?.(input, source) ?? true;
      }}
    />
  );
};
