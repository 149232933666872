import { SmartRichTextInput } from "@react-admin/ra-ai";
import HardBreak from "@tiptap/extension-hard-break";
import StarterKit from "@tiptap/starter-kit";
import { tail } from "lodash";
import { DefaultEditorOptions, RichTextInput } from "ra-input-rich-text";
import { useRecordContext, useResourceContext } from "react-admin";
import { HasPlatformExtension } from "../HasPlatformExtension";

const ItinRichTextArea = ({
  label = false,
  isRequired = false,
  source = "description",
  height = "260px",
  placeholder = "Compose an epic...",
  helperText = false,
  titleVariant = "h4",
  ...props
}) => {
  const record = useRecordContext();
  const resource = useResourceContext();
  const input_props = {
    placeholder,
    source,
    label,
    helperText,
    sx: { "& .tiptap": { height: height, overflow: "scroll" } },
    isRequired,
    // editorOptions: {
    //   ...DefaultEditorOptions,
    //   extensions: [
    //     StarterKit.configure({ hardBreak: false }),
    //     ...tail(DefaultEditorOptions.extensions),
    //     HardBreak.extend({
    //       addKeyboardShortcuts() {
    //         return {
    //           Enter: () => this.editor.commands.setHardBreak(),
    //         };
    //       },
    //     }),
    //   ],
    // },
  };

  return (
    <HasPlatformExtension
      platform_extension="AiExtension"
      fallback={<RichTextInput {...input_props} />}
    >
      <SmartRichTextInput
        meta={{ resource: resource, id: record?.id }}
        {...input_props}
      />
    </HasPlatformExtension>
  );
};

export default ItinRichTextArea;
