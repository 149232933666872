import { RadioButtonGroupInput, SelectInput, required } from "react-admin";
import { useEnumChoices } from "~/hooks";
import { useState, useEffect } from "react";
import { FormHelperText } from "@mui/material";

export const EnumChoiceInput = ({
  isRadio,
  onChoices,
  isRequired = true,
  fullWidth,
  descriptions = [],
  input = {}, // Default to an empty object if input is not provided
  ...props
}) => {
  const { data = [], isLoading } = useEnumChoices(props);
  const choices = onChoices?.(data) || data;

  // Set the initial selected value based on the input value or use a fallback
  const [selectedValue, setSelectedValue] = useState(input.value || "");
  const [helperText, setHelperText] = useState("");

  useEffect(() => {
    if (!isLoading) {
      const valueToUse =
        input.value ||
        selectedValue ||
        (choices.length > 0 ? choices[0].id : "");
      setSelectedValue(valueToUse);
      const descriptionObj = descriptions.find(
        (desc) => desc.value === valueToUse
      );
      setHelperText(descriptionObj ? descriptionObj.description : "");
    }
  }, [isLoading, input.value, selectedValue, descriptions, choices]);

  const handleChange = (event) => {
    const value = event.target.value;
    if (input.onChange) {
      input.onChange(value); // Ensure we call input.onChange if it exists
    }
    setSelectedValue(value);
    const descriptionObj = descriptions.find((desc) => desc.value === value);
    setHelperText(descriptionObj ? descriptionObj.description : "");
  };

  if (isRadio)
    return (
      <>
        <RadioButtonGroupInput
          choices={choices}
          dir="row"
          validate={isRequired && required()}
          onChange={handleChange}
          {...input} // Pass input props if they exist
          {...props}
        />
        <FormHelperText>{helperText}</FormHelperText>
      </>
    );

  return (
    <>
      <SelectInput
        choices={choices}
        validate={isRequired && required()}
        isLoading={isLoading}
        InputProps={{ placeholder: "test" }}
        fullWidth={fullWidth}
        onChange={handleChange}
        {...input} // Pass input props if they exist
        {...props}
      />
      <FormHelperText>{helperText}</FormHelperText>
    </>
  );
};
