import { Grid, Typography } from "@mui/material";
import { Edit, SimpleForm, TextInput, useEditContext } from "react-admin";
import { StyledToolBar } from "~/components";
import { DoMagicButton } from "~/components/buttons/DoMagicButton";
import { GridCard } from "~/components/GridCard";
import { TagSidebar } from "../taggings";
import { UrlsList } from "../urls/UrlsList";

export const MediaEdit = () => {
  return (
    <Edit mutationMode="pessimistic" redirect={false}>
      <MediaForm />
    </Edit>
  );
};

export const MediaInfoCard = () => {
  const { record, isLoading } = useEditContext();
  if (isLoading) return null;
  return (
    <GridCard title="File Info">
      <Typography variant="body2">File: {record.filename}</Typography>
      <Typography variant="body2">Type: {record.content_type}</Typography>
      <Typography variant="body2">
        Size: {Math.round(record.filesize / 1024)}k
      </Typography>
      <Typography variant="body2">
        Dimensions: {record.metadata.width} x {record.metadata.height}
      </Typography>
    </GridCard>
  );
};
export const MediaForm = () => {
  const { record } = useEditContext();

  return (
    <SimpleForm toolbar={<StyledToolBar />}>
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <img src={record.url} alt={record.filename} height="600px" />
          <GridCard title="Tags">
            <TagSidebar />
          </GridCard>
          <GridCard title="Related Web Pages">
            <UrlsList />
          </GridCard>
        </Grid>
        <Grid item xs={3}>
          {/* <ImageField
            source="url"
            sx={{
              height: "300px",
              width: "100%",
              "& img": {
                width: "250px",
                height: "250px",
                objectFit: "contain",
              },
            }}
          /> */}
          <Grid container spacing={2}>
            <GridCard title="Linked To">
              <Typography variant="h3">
                Linked to {record.record_type}: {record.record_name}
              </Typography>
            </GridCard>
            <GridCard title="Caption/Alt">
              <TextInput source="caption" fullWidth multiline />
            </GridCard>
            <MediaInfoCard />
            <GridCard title="EXIF"></GridCard>
            <GridCard title="Attibution"></GridCard>
            <DoMagicButton />
          </Grid>
        </Grid>
      </Grid>
    </SimpleForm>
  );
};
