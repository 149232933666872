import { FormTab } from "react-admin";
import ItinRichTextArea from "~/components/inputs/ItinRichTextArea";
import { useCurrentPlatform } from "~/context";
export const EditDescriptionTab = (props) => {
  const { getTerm } = useCurrentPlatform();
  const eventTerm = getTerm("event");
  return (
    <FormTab {...props} label="Description">
      <ItinRichTextArea
        label="Description"
        description={`Give a short description of this ${eventTerm}.`}
        height="350px"
        placeholder="Compose an epic.."
        source="description"
        isRequired={false}
      />
    </FormTab>
  );
};
