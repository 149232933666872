import { Box, Stack, Typography } from "@mui/material";
import { IfCanAccess } from "@react-admin/ra-rbac";
import {
  BooleanInput,
  Create,
  SimpleForm,
  TextInput,
  required,
  useGetIdentity,
} from "react-admin";
import { ItinLoading, StyledToolBar } from "~/components";
import { RegionInput } from "~/components/inputs/RegionInput";
import { useCurrentPlatform } from "~/context";
export const EventCreate = () => {
  const { isLoading } = useGetIdentity();
  const { loading, getTerm } = useCurrentPlatform();
  const eventCapTerm = getTerm("event", { capitalize: true });
  const eventsTerm = getTerm("event", { plural: true });
  const eventTerm = getTerm("event");
  if (isLoading || loading) return <ItinLoading />;
  return (
    <Create redirect="edit">
      <SimpleForm
        variant="standard"
        defaultValues={{
          target_type: "Region",
        }}
        toolbar={<StyledToolBar />}
      >
        <Typography variant="h4" pb="16px">
          {`New ${eventCapTerm}`}
        </Typography>
        <Stack width="97%" mb="40px">
          <Stack direction="row" gap={3} width="98%">
            <TextInput
              label={`${eventCapTerm} Title`}
              source="name"
              multine="true"
              validate={required()}
              fullWidth
            />
            <Box display="flex" flexDirection="column" width="100%">
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                mb="10px"
              >
                <Typography variant="caption">Cost*</Typography>

                <Box
                  display="flex"
                  alignItems={"center"}
                  height="20px"
                  gap={0.5}
                >
                  <Typography variant="caption" color={"#718096"}>
                    {`Enter 0 for ${eventsTerm} that are free.`}
                  </Typography>
                </Box>
              </Box>
              <TextInput
                label=""
                source="cost"
                validate={required()}
                fullWidth
              />
            </Box>
            {/* <SelectInput
              source="event_series"
              label="Event series"
              choices={[
                { id: "norepeat", name: "Does not repeat" },
                { id: "daily", name: "Daily" },
                { id: "weekly", name: "Weekly" },
                { id: "monthly", name: "Monthly" },
                { id: "annually", name: "Annually" },
              ]}
              fullWidth
            /> */}
          </Stack>

          <Stack direction="row" gap={3} width="98%">
            <TextInput label="Ticket URL" source="ticket_url" />
            <Stack direction="row" gap={3} width="98%">
              <RegionInput source="target_id" />
            </Stack>
          </Stack>
          <BooleanInput
            label={`Make this a featured ${eventTerm}`}
            source="featured"
            sx={{ mt: "32px" }}
          />
        </Stack>
      </SimpleForm>
    </Create>
  );
};
