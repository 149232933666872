import { Grid, Typography } from "@mui/material";
import { Show, TabbedShowLayout, useRecordContext } from "react-admin";

import { ImageField, TextField } from "react-admin";
import { GridCard } from "~/components/GridCard";

import { Box } from "@mui/material";
import { ListBase } from "react-admin";
import { SimpleMapList, Stats } from "~/components";
import { MapDataProvider } from "~/components/maps//MapDataProvider";

import { useCurrentPlatform } from "~/context";
import { InviteCollaborator } from "~/components/buttons/InviteCollaborator";
import { ReportFilterProvider } from "~/context/ReportFilterContext";
import { TourismoDateRangePicker } from "~/components/TourismoDateRangePicker";

export const DmoMap = (props) => {
  const record = useRecordContext();
  if (!record) return <></>;
  //would be nice to show events and listings as well
  return (
    <MapDataProvider>
      <Box height={"55vh"} width="100%" marginTop={"0px"} marginBottom={"0px"}>
        <ListBase
          resource="regions"
          filter={{ id: record.region_ids }}
          pagination={[120]}
          perPage={120}
        >
          <SimpleMapList toolButtons={false} showPins={false} />
        </ListBase>
      </Box>
    </MapDataProvider>
  );
};

export const DmoShow = () => {
  const { currentPlatform } = useCurrentPlatform();

  return (
    <Show>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="Summary">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Grid container>
                <Grid item xs={4}>
                  <ImageField source="logo.url" />
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="h3">
                    <TextField source="name" variant="h3" />
                  </Typography>
                  <TextField source="description" />
                </Grid>
              </Grid>
              <Stats />
            </Grid>
            <Grid item xs={6}>
              <GridCard>
                <DmoMap />
              </GridCard>

              <InviteCollaborator />
            </Grid>
          </Grid>
        </TabbedShowLayout.Tab>

        <TabbedShowLayout.Tab label="Regional Web Traffic">
          <Grid container spacing={2}>
            <ReportFilterProvider>
              <TourismoDateRangePicker />
              <Stats category="web" />
            </ReportFilterProvider>
          </Grid>
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Regional App Activity">
          <Grid container spacing={2}>
            <ReportFilterProvider>
              <TourismoDateRangePicker />
              <Stats category="app" />
            </ReportFilterProvider>
          </Grid>
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Users & Surveys">
          <Grid container spacing={2}>
            <ReportFilterProvider>
              <TourismoDateRangePicker />
              <Stats category="users" />
            </ReportFilterProvider>
          </Grid>
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default DmoShow;
