import { Box, Grid } from "@mui/material";
import { get } from "lodash";
import {
  Datagrid,
  DateField,
  List,
  ReferenceField,
  TextField,
  useEditContext,
  useRecordContext,
} from "react-admin";
import { EnumField, PolymorphicReferenceField } from "~/components/fields";
import { EnumChoiceInput } from "~/components/inputs";
import { classifyResource } from "~/helpers";
import { ManualCheckIn } from "../platform_user_check_ins/ManualCheckIn";

const filters = [
  <EnumChoiceInput
    label="Category"
    source="category"
    emptyText="Category"
    isRequired={false}
    SelectProps={{ displayEmpty: true, notched: false }}
  />,
];

const LogPanel = () => {
  const record = useRecordContext();
  const json_data = get(record, "json_data");
  return (
    <Grid container>
      <Grid item xs={6}>
        <Box
          as="pre"
          sx={{
            "& .show-more-less-clickable": { cursor: "pointer" },
            textWrap: "wrap",
          }}
        >
          {JSON.stringify(json_data, null, 2)}
        </Box>
      </Grid>
      <Grid item xs={6}>
        {json_data?.location && record.target_type == "Listing" && (
          <PolymorphicReferenceField
            source="target_id"
            reference_source="target_type"
            link={false}
          >
            <ManualCheckIn
              location={json_data?.location}
              target_type={record.target_type}
              target_id={record.target_id}
              user_id={record.user_id}
            />
          </PolymorphicReferenceField>
        )}
      </Grid>
    </Grid>
  );
};

export const LogList = (props) => {
  const { resource, record } = useEditContext();
  return (
    <List
      sx={{
        "& .MuiToolbar-root": { padding: "0" },
        width: "100%",
      }}
      resource="logs"
      filter={
        record && {
          target_id: record.id,
          target_type: classifyResource(resource),
        }
      }
      filters={filters}
    >
      <Datagrid bulkActionButtons={false} expand={<LogPanel />}>
        <DateField source="created_at" showTime label="Time" />
        <EnumField source="category" />
        <TextField source="name" label="Note" />
        <ReferenceField
          source="user_id"
          reference="users"
          // link={false}
          sortable={false}
        />
        <ReferenceField source="owner_id" reference="users" sortable={false} />
        {!record && (
          <PolymorphicReferenceField
            source="target_id"
            reference_source="target_type"
          />
        )}
      </Datagrid>
    </List>
  );
};
