import { Box, Grid } from "@mui/material";
import { snakeCase } from "lodash";
import { AutocompleteInput, TextInput, required } from "react-admin";
import { useWatch } from "react-hook-form";
import { LocationFields } from "~/components";
import { useCurrentPlatform } from "~/context";
import { RegionInput } from "~/components/inputs/RegionInput";
import { useListingTypes } from "~/hooks";

export const BasicFields = ({ show, ...props }) => {
  const fieldValues = useWatch({
    name: [
      "location_name",
      "street_address",
      "city",
      "province",
      "country",
      "postal_code",
      "main_type",
    ],
  });
  const { data: main_types, isLoading } = useListingTypes();
  const boxStyling = {
    borderRadius: "12px",
    border: "1px solid",
    borderColor: `grey.200`,
    padding: "16px",
  };
  const { getTerm } = useCurrentPlatform();
  const listingTerm = getTerm("listing", { capitalize: true });
  if (!show && !fieldValues.filter(Boolean).length) return null;
  return (
    <Grid container spacing={2} {...props}>
      <Grid item xs={6}>
        <Box sx={boxStyling}>
          <TextInput
            source="location_name"
            label="Name"
            validate={required()}
            fullWidth
          />
          {/* <TextInput source="website" label="Website" fullWidth /> */}
          <AutocompleteInput
            label={`${listingTerm} Type`}
            source="main_type"
            choices={main_types}
            onCreate={(name) => {
              const newType = { id: snakeCase(name), name };
              main_types.push(newType);
              return newType;
            }}
            loading={isLoading}
          />
          <RegionInput />
          <TextInput source="street_address" fullWidth />
          <Grid container columnSpacing={1}>
            <Grid item xs={12} sm={6}>
              <TextInput source="city" fullWidth />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput source="province" fullWidth />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput source="country" fullWidth />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput source="postal_code" fullWidth />
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <LocationFields />
      </Grid>
    </Grid>
  );
};
