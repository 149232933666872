import {
  BasicTextStyleButton,
  BlockTypeSelect,
  FormattingToolbar as BNFormattingToolbar,
  ColorStyleButton,
  CreateLinkButton,
  FileCaptionButton,
  FileReplaceButton,
  NestBlockButton,
  TextAlignButton,
  UnnestBlockButton,
} from "@blocknote/react";
import { ImageToolbarbutton } from "../BlockNoteImage/ImageToolbarButton";
import { BlockPreview } from "./BlockPreview";
import { SocialsToolbarButtons } from "../BlockNotesSocials/SocialsToolbarButtons";
import { CustomColorStyleButton } from "./ColorStyleButton";

export const FormattingToolbar = () => {
  return (
    <BNFormattingToolbar>
      <BlockTypeSelect key={"blockTypeSelect"} />
      <SocialsToolbarButtons key={"socialsToolbarButtons"} />
      <ImageToolbarbutton />
      <FileCaptionButton key={"fileCaptionButton"} />
      <FileReplaceButton key={"replaceFileButton"} />
      <BasicTextStyleButton basicTextStyle={"bold"} key={"boldStyleButton"} />
      <BasicTextStyleButton
        basicTextStyle={"italic"}
        key={"italicStyleButton"}
      />
      <BasicTextStyleButton
        basicTextStyle={"underline"}
        key={"underlineStyleButton"}
      />
      <BasicTextStyleButton
        basicTextStyle={"strike"}
        key={"strikeStyleButton"}
      />
      <TextAlignButton textAlignment={"left"} key={"textAlignLeftButton"} />
      <TextAlignButton textAlignment={"center"} key={"textAlignCenterButton"} />
      <TextAlignButton textAlignment={"right"} key={"textAlignRightButton"} />
      <ColorStyleButton key={"colorStyleButton"} />
      <CustomColorStyleButton key="customColorStyleButton" />
      <NestBlockButton key={"nestBlockButton"} />
      <UnnestBlockButton key={"unnestBlockButton"} />
      <CreateLinkButton key={"createLinkButton"} />
      <BlockPreview key="blockPreview" />
    </BNFormattingToolbar>
  );
};
