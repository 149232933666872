import { Box, Grid, Typography } from "@mui/material";
import {
  ArrayField,
  ChipField,
  Create,
  Datagrid,
  DateField,
  Edit,
  FormTab,
  FunctionField,
  RecordContextProvider,
  ReferenceManyField,
  SelectInput,
  SimpleForm,
  SingleFieldList,
  TabbedForm,
  TextField,
  TextInput,
  email,
  required,
  useGetIdentity,
  useGetManyReference,
  useRecordContext,
} from "react-admin";
import { ItinLoading, Stats, StyledToolBar } from "~/components";
import { SingleImageInput } from "~/components/images/SingleImageInput";
import { EnumChoiceInput } from "~/components/inputs";
import { useCurrentPlatform } from "~/context";
import { useRoles } from "~/hooks";
import { useCurrentView } from "~/hooks/useCurrentView";
import { LogList } from "../logs/LogList";
import { PlatformUserList } from "../platform_users";
import { UserInteractionsUserProfile } from "./UserInteractionsUserProfile";
import { UserTags } from "./UserTags";

export const UserCreate = () => {
  return (
    <Create mutationMode="pessimistic">
      <UserCreateForm />
    </Create>
  );
};

export const UserEdit = () => {
  return (
    <Edit mutationMode="pessimistic">
      <UserForm />
    </Edit>
  );
};
const UserCreateForm = () => {
  const view = useCurrentView();
  const { getTerm } = useCurrentPlatform();
  const userTerm = getTerm("user", { plural: false, capitalize: true });
  return (
    <SimpleForm redirect="edit" toolbar={<StyledToolBar />}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" pb="16px">
            {`${userTerm} Info`}
          </Typography>
          <TextInput source="name" fullWidth validate={required()} />
          <TextInput
            source="email"
            fullWidth
            validate={[required(), email()]}
          />

          <RoleSelect source="role" fullWidth validate={required()} />
        </Grid>
        {view === "edit" && (
          <Grid item xs={12} md={6}>
            <Typography variant="h4" pb="16px">
              Image
            </Typography>
            <SingleImageInput source="avatar" show_caption={false} />
          </Grid>
        )}
      </Grid>
    </SimpleForm>
  );
};

const UserForm = () => {
  const { data: identity } = useGetIdentity();

  const record = useRecordContext();
  const { currentPlatform } = useCurrentPlatform();
  const { data: platform_users = [], isLoading } = useGetManyReference(
    "platform_users",
    {
      target: "user_id",
      id: record.id,
      filter: { platform_id: currentPlatform?.id },
    },
    {
      enabled: !!currentPlatform,
    }
  );

  const view = useCurrentView();
  const { getTerm } = useCurrentPlatform();
  const userTerm = getTerm("user", { plural: false, capitalize: true });
  const pointTerm = getTerm("point", { plural: true, capitalize: true });
  const levelTerm = getTerm("platform_level", {
    plural: false,
    capitalize: true,
  });
  if (isLoading) return <ItinLoading />;
  const platform_user = platform_users[0] || {};
  const { level, last_log_in, points = 0 } = platform_user;
  return (
    <TabbedForm redirect={false} variant="standard" toolbar={<StyledToolBar />}>
      <FormTab label={`${userTerm} Info`}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" pb="16px">
              {`${userTerm} Info`}
            </Typography>
            <TextInput source="name" fullWidth validate={required()} />
            <TextInput
              source="email"
              fullWidth
              validate={[required(), email()]}
            />
            <EnumChoiceInput
              source="role"
              fullWidth
              validate={required()}
              disabled={identity.role === "super_admin"}
            />

            {/* <Box display="flex" flexWrap="wrap" marginY={2}>
              {!!level?.name && (
                <UserTags>{`${userTerm} ${levelTerm}: ${level?.name}`}</UserTags>
              )}
              <UserTags>{`${userTerm} ${pointTerm}: ${points}`}</UserTags>

              {last_log_in && (
                <UserTags>{`Last Checkin: ${last_log_in?.slice(
                  0,
                  22
                )}`}</UserTags>
              )}
            </Box> */}
          </Grid>
          {view === "edit" && (
            <Grid item xs={12} md={6}>
              <Typography variant="h4">Image</Typography>
              <SingleImageInput source="avatar" show_caption={false} />
            </Grid>
          )}
          <PlatformUserList />
        </Grid>
      </FormTab>
      <FormTab label="Check-in History">
        <RecordContextProvider value={platform_user}>
          <UserInteractionsUserProfile />
        </RecordContextProvider>
      </FormTab>
      <FormTab label={`Activity Log`}>
        <LogList />
      </FormTab>
      <FormTab label={`Reports`}>
        <Stats />
      </FormTab>

      <FormTab label="Subscriptions">
        <ReferenceManyField
          reference="user_subscriptions"
          target="user_id"
          label={false}
        >
          <Datagrid bulkActionButtons={false}>
            <DateField source="created_at" />
            <TextField source="status" />
            <TextField source="name" />
            <FunctionField
              label={false}
              render={(record) => `$ ${record.subscription_amount / 100.0}`}
            />
            <ArrayField source="user_subscription_targets">
              <SingleFieldList linkType={false}>
                <ChipField source="name" size="small" />
              </SingleFieldList>
            </ArrayField>
            {/* <Button
                label="Manage Subscription"
                onClick={() => updateMany}
              ></Button> */}
          </Datagrid>
        </ReferenceManyField>
      </FormTab>
    </TabbedForm>
  );
};

const PlatformSelectInput = (props) => {
  //needs to be the context of the child records from the ReferenceInput above, currently leaves everything enabled, which is suboptimal, but not catastrophic
  //console.log(record); //!!record.role
  return (
    <SelectInput label={false} style={{ width: "250px" }} disabled={false} />
  );
};

const RoleSelect = (props) => {
  const { data, isLoadingUser } = useGetIdentity();
  const { data: roles = [], isLoading } = useRoles();
  if (isLoadingUser) return null;
  if (data.role != "super_admin") {
    return null;
  }
  const choices = roles.map(({ id, name }) => {
    return {
      id,
      name,
    };
  });
  if (isLoading) return null;
  return <SelectInput choices={choices} isLoading={isLoading} {...props} />;
};
