import { FormTab, TextInput } from "react-admin";
import { ContentInlineEditList, ContentList } from "~/resources/contents";
import { Box } from "@mui/material";
import ItinRichTextArea from "~/components/inputs/ItinRichTextArea";
import { useCurrentPlatform } from "~/context";

const EditContentTab = (props) => {
  const { getTerm } = useCurrentPlatform();
  const itinTerm = getTerm("itinerary");
  return (
    <FormTab {...props} label="Content">
      <Box
        display="grid"
        sx={{ width: "100%" }}
        gridTemplateColumns="repeat(12,1fr)"
        gap={2}
      >
        <Box gridColumn={"span 12"}>
          <TextInput
            title="Subtitle"
            placeholder="Keep it punchy.."
            source="subtitle"
            isRequired={false}
            fullWidth
            description={`A short tagline that goes beneath the name of the ${itinTerm}. Example: ‘More than you imagined!’`}
          ></TextInput>
        </Box>
        <Box gridColumn="span 6">
          <ItinRichTextArea
            label="Short Description"
            helperText={`A summary of the full description. Displayed where many ${getTerm(
              "itinerary",
              { plural: true }
            )} are shown.`}
            height="300px"
            placeholder="Short and Sweet.."
            source="short_description"
            isRequired={false}
          />
        </Box>
        <Box gridColumn="span 6">
          <ItinRichTextArea
            label="Full Description"
            helperText={`A full description of your ${itinTerm}.  This will appear on expanded views of your ${itinTerm} for the ${getTerm(
              "user"
            )}.`}
            height="300px"
            placeholder="Compose an epic.."
            source="description"
            isRequired={false}
          />
        </Box>
      </Box>

      <ContentInlineEditList />
    </FormTab>
  );
};
export default EditContentTab;
