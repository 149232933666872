import { Button, Grid } from "@mui/material";
import { useState } from "react";
import { Labeled } from "react-admin";
import { useFormContext, useWatch } from "react-hook-form";
import { useIntersectionObserver } from "usehooks-ts";
import { getImageIsSolidColor } from "~/helpers";

export const IngestionImageSelect = ({ images }) => {
  const [ingest_hero_url, hero] = useWatch({
    name: ["ingest_hero_url", "hero"],
  });
  const { setValue, unregister } = useFormContext();
  if (!images?.length) return null;

  return (
    <Labeled as={Grid} label="Select an Image" container item xs={12}>
      <Grid container item xs={12} columnSpacing={1} rowSpacing={2}>
        <ImageButton
          source={hero?.url}
          selected={!ingest_hero_url}
          onClick={() => unregister("ingest_hero_url")}
          checkImage={false}
        />
        {images.map((img) => {
          const selected = img.url === ingest_hero_url;
          return (
            <ImageButton
              key={img.url}
              source={img.url}
              selected={selected}
              onClick={() => {
                setValue("ingest_hero_url", img.url, {
                  shouldDirty: true,
                  shouldTouch: true,
                });
              }}
            />
          );
        })}
      </Grid>
    </Labeled>
  );
};

export const ImageButton = ({
  source,
  selected,
  checkImage = true,
  ...props
}) => {
  const [invalidImage, setInvalidImage] = useState(false);
  const { isIntersecting, ref } = useIntersectionObserver({
    threshold: 0.5,
    freezeOnceVisible: true,
  });
  const [loading, setLoading] = useState(true);

  if (!source || invalidImage) return null;
  return (
    <Grid item key={source} ref={ref} sx={{ opacity: loading ? 0 : 1 }}>
      <Button
        size="small"
        variant={selected ? "contained" : "outlined"}
        sx={{ borderRadius: 2, padding: 2, flexGrow: 1 }}
        {...props}
      >
        {isIntersecting && (
          <img
            onLoad={({ target }) => {
              setLoading(false);
              if (!checkImage) return;
              const image = new Image();
              image.src = `/images/proxy?url=${target.src}`;
              image.onload = () => {
                setInvalidImage(getImageIsSolidColor(image));
              };
            }}
            onError={() => setInvalidImage(true)}
            src={source}
            height={128}
            style={{ borderRadius: 5 }}
            alt=""
          />
        )}
      </Button>
    </Grid>
  );
};
