import { Typography } from "@mui/material";
import { Loading } from "react-admin";
import { useStats } from "~/hooks";
import { SummaryCards } from "./SummaryCards";
import { DEFAULT_STATS_ICONS } from "./stats_constants";
export const Stats = (props) => {
  const { data, isLoading } = useStats(props);
  if (isLoading) return <Loading />;
  if (typeof data == "undefined" || data == null) return <></>;
  return (
    <>
      <Typography variant="h4" sx={{ marginBottom: "20px" }}>
        {data.title}
      </Typography>
      <SummaryCards cards={data.cards} />
    </>
  );
};
