import { MagicWand } from "@phosphor-icons/react";
import { Button } from "@mui/material";

import {
  useCreate,
  useNotify,
  useRecordContext,
  useResourceContext,
} from "react-admin";
import { useValidateFields } from "~/hooks";
import { Sparkles } from "../Sparkles";
import { classifyResource } from "~/helpers";

export const CreateIngestionButton = ({
  onClick,
  website,
  onSuccess,
  fields,
  ...props
}) => {
  const record = useRecordContext();
  const resource = useResourceContext();

  const [create] = useCreate();
  const notify = useNotify();
  const validate = useValidateFields();
  const handleClick = async () => {
    onClick?.();
    if (!(await validate(fields))) return;

    const ingestion = await create(
      "ingestions",
      {
        data: {
          url: website,
          target_type: classifyResource(resource),
          target_id: record?.id,
        },
      },
      { returnPromise: true }
    ).catch(() => {
      notify("ra.notification.http_error", { type: "error" });
    });
    if (ingestion?.id) onSuccess(ingestion);
  };

  return (
    <Sparkles maxSize={24} show={!!website && !props.disabled}>
      <Button
        startIcon={<MagicWand />}
        onClick={handleClick}
        variant={"contained"}
        disabled={props.disabled || !website}
        {...props}
      >
        {props.label || "Let's Make some Magic!"}
      </Button>
    </Sparkles>
  );
};
