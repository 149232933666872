import { Close, ControlPoint } from "@mui/icons-material";
import { Box, Chip, Dialog, Grid, IconButton, Typography } from "@mui/material";
import { IfCanAccess } from "@react-admin/ra-rbac";
import { Menu, MenuItem } from "@szhsin/react-menu";
import { groupBy } from "lodash";
import { useMemo, useState } from "react";
import {
  ListContextProvider,
  RecordContextProvider,
  ResourceContextProvider,
  WithListContext,
  useCreate,
  useGetList,
  useGetManyReference,
  useRecordContext,
  useResourceContext,
} from "react-admin";
import { ChipWithConfirmDelete } from "~/components/fields/ChipWithConfirmDelete";
import { useCurrentPlatform } from "~/context";
import { titleize } from "~/helpers";
import { useTargetType } from "~/hooks";
import { TaggingForm } from "./TaggingForm";

export const TagSidebar = (props) => {
  const { getTerm } = useCurrentPlatform();
  const tagTerm = getTerm("tag", { plural: false, capitalize: true });
  const record = useRecordContext(props);
  const resource = useResourceContext(props);
  const [tagging, setTagging] = useState();
  const [open, setOpen] = useState(false);
  const [createTagging] = useCreate();
  const target_type = useTargetType();
  const {
    data: taggings = [],
    isLoading: isLoadingTaggings,
    refetch,
  } = useGetManyReference(
    "taggings",
    {
      target: "target_id",
      id: record?.id,
      pagination: { page: 1, perPage: null },
      filter: { target_type },
    },
    { enabled: !!record?.id }
  );

  const {
    data: allTags = [],
    isLoading: isLoadingAllTags,
    refetch: refetchTags,
  } = useGetList("tags", {
    pagination: { page: 1, perPage: null },
    sort: { field: "name", order: "ASC" },
  });

  const all_unselected_tags = useMemo(
    () =>
      groupBy(
        allTags.filter(
          ({ id }) => !taggings.find(({ tag_id }) => tag_id == id)
        ),
        "category"
      ),

    [allTags, taggings]
  );
  const all_grouped_tags = useMemo(
    () => groupBy(taggings, "category"),
    [taggings]
  );

  if (isLoadingTaggings || isLoadingAllTags || !record) return null;

  const handleAddTag = (id) => {
    createTagging(
      "taggings",
      {
        data: {
          tag_id: id,
          target_type,
          target_id: record.id,
        },
      },
      { onSuccess: refetch }
    );
  };
  const handleOpenCreateDialog = () => {
    setOpen(true);
  };

  return (
    <ResourceContextProvider value={"taggings"}>
      <ListContextProvider value={{ data: all_grouped_tags, refetch }}>
        <WithListContext
          render={({ data }) => (
            <>
              {Object.entries(data).map(
                ([tag_category_name, group_of_taggings]) => (
                  <Box key={tag_category_name}>
                    <Box sx={{ padding: "20px 0px 2px 0px", display: "flex" }}>
                      <Typography
                        variant="h6"
                        color="grey.600"
                        display="inline-flex"
                        marginRight={"4px"}
                      >
                        {titleize(tag_category_name)}
                      </Typography>

                      <IfCanAccess
                        record={record}
                        resource={resource}
                        action="edit"
                      >
                        <Menu
                          overflow="auto"
                          anchor="auto"
                          menuButton={
                            <ControlPoint fontSize="small" color="primary" />
                          }
                        >
                          {all_unselected_tags[tag_category_name]?.map(
                            (tag) => (
                              <MenuItem
                                key={tag.id}
                                onClick={() => handleAddTag(tag.id)}
                              >
                                <Chip
                                  size="small"
                                  variant="outlined"
                                  label={tag.name}
                                  style={{
                                    backgroundColor: tag.color,
                                    border: 0,
                                  }}
                                />
                              </MenuItem>
                            )
                          )}
                        </Menu>
                      </IfCanAccess>
                    </Box>
                    <Grid container spacing={0.5}>
                      {group_of_taggings.map((tagging) => (
                        <RecordContextProvider key={tagging.id} value={tagging}>
                          <Grid item sx={{ overflow: "hidden" }}>
                            <ChipWithConfirmDelete
                              tooltipSource="note"
                              onClick={() => {
                                setTagging(tagging);
                                handleOpenCreateDialog();
                              }}
                            />
                          </Grid>
                        </RecordContextProvider>
                      ))}
                    </Grid>
                  </Box>
                )
              )}
            </>
          )}
        />

        <Box mt={1}>
          <IfCanAccess record={record} resource={resource} action="edit">
            <Chip
              icon={<ControlPoint />}
              size="small"
              variant="outlined"
              onClick={handleOpenCreateDialog}
              label={`Add ${tagTerm}`}
              color="primary"
            />
          </IfCanAccess>
        </Box>
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="form-dialog-title"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                backgroundColor: "white",
                width: "100%",
                padding: 5,
                maxWidth: "500px", // Set your width here
              },
            },
          }}
        >
          <IconButton
            aria-label="close"
            onClick={() => setOpen(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "grey.500",
            }}
          >
            <Close />
          </IconButton>
          <TaggingForm
            record={tagging}
            onClose={() => {
              refetch();
              refetchTags();
              setOpen(false);
              setTagging(null);
            }}
            target_type={target_type}
            target={record}
          />
        </Dialog>
      </ListContextProvider>
    </ResourceContextProvider>
  );
};
