import { useMousePosition } from "~/hooks";
import { Box } from "@mui/material";
import { mergeSx } from "~/helpers";
const SIZE = 40;
export const CursorIcon = ({ Icon, show, sx, ...props }) => {
  const { x, y } = useMousePosition(show);
  if (!show) return null;
  return (
    <Box
      position="fixed"
      top={0}
      left={0}
      zIndex={100}
      fontSize={SIZE}
      sx={mergeSx(
        {
          transform: `translate(${x - SIZE / 2}px, ${y - SIZE}px)`,
          pointerEvents: "none",
          color: "#EA4335",
        },
        sx
      )}
      {...props}
    >
      <Icon weight="fill" />
    </Box>
  );
};
