import { Delete } from "@mui/icons-material";
import { ClickAwayListener, MenuItem, MenuList, Paper } from "@mui/material";
import { DataF as MapsData, OverlayView } from "@react-google-maps/api";
import { useState } from "react";
import { useInput } from "react-admin";
import { useMapData } from "./MapDataProvider";
import {
  convertMultiPolyToPolyFeature,
  dataJsonToMultiPolyString,
} from "./helpers";

export const Data = ({ onDraw }) => {
  const { dataRef } = useMapData();
  const {
    field: { value, onChange },
  } = useInput({ source: "bounds" });
  const feature = value ? convertMultiPolyToPolyFeature(value) : null;
  const handleDraw = () => {
    onDraw(dataRef.current);
    dataRef.current.toGeoJson((json) => {
      const newValue = dataJsonToMultiPolyString(json);
      if (newValue !== value) onChange(newValue);
    });
  };
  const [contextEvent, setContextEvent] = useState();
  const handleClick = ({ feature }) => {
    dataRef.current.revertStyle();
    dataRef.current.overrideStyle(feature, {
      fillOpacity: 0.75,
      draggable: true,
      editable: true,
    });
  };
  return (
    <>
      <MapsData
        onLoad={(data) => {
          dataRef.current = data;
          data.addListener("contextmenu", (e) => {
            handleClick(e);
            setContextEvent(e);
          });
          data.addGeoJson(feature);
        }}
        onAddFeature={handleDraw}
        onSetGeometry={handleDraw}
        onRemoveFeature={handleDraw}
        onClick={handleClick}
        options={{
          style: {
            fillColor: "#283593",
            zIndex: 95,
          },
        }}
      />
      {contextEvent && (
        <ClickAwayListener onClickAway={() => setContextEvent()}>
          <OverlayView
            mapPaneName="overlayMouseTarget"
            position={contextEvent.latLng}
          >
            <Paper>
              <MenuList>
                <MenuItem
                  sx={{ color: "error.main" }}
                  onClick={() => {
                    dataRef.current.remove(contextEvent.feature);
                  }}
                >
                  <Delete sx={{ mr: 1 }} />
                  Remove
                </MenuItem>
              </MenuList>
            </Paper>
          </OverlayView>
        </ClickAwayListener>
      )}
    </>
  );
};
