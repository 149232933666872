import { Calendar } from "@phosphor-icons/react";
import { Datagrid, NumberField, ReferenceField, TextField } from "react-admin";
import AvatarField from "~/components/AvatarField";
import {
  CircularProgressField,
  EnumSelectField,
  FeaturedField,
} from "~/components/fields";
import { ToggleList } from "~/components/lists";
import { useCurrentPlatform } from "~/context";
import { EventCalendar } from "./EventCalendar";
import { eventCardData } from "./eventCardData";
export const EventList = () => {
  return <ToggleList modes={MODES} />;
};

const ListData = () => {
  const { getTerm } = useCurrentPlatform();
  const regionTerm = getTerm("region", { capitalize: true, plural: false });
  return (
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <EnumSelectField source="status" />
      <AvatarField source="hero" label={"false"} />
      <TextField source="name" />
      <ReferenceField source="region_id" reference="regions" label={regionTerm}>
        <TextField source="name" />
      </ReferenceField>
      <NumberField source="total_tag_count" label={"Tags"} />
      <FeaturedField source="featured" />
      <CircularProgressField
        source="record_completion_score.score"
        tooltipSource="record_completion_score.deficiencies"
        textAlign="center"
        sortable={false}
        label="Score"
      />
    </Datagrid>
  );
};

const MODES = [
  { name: "Grid", cardData: eventCardData },
  { name: "List", view: <ListData /> },
  {
    name: "Calendar",
    view: <EventCalendar />,
    icon: <Calendar />,
    resource: "event_times",
  },
];
