import { ItineraryCreate } from "./ItineraryCreate";
import { ItineraryEdit } from "./ItineraryEdit";
import { ItineraryList } from "./ItineraryList";
import { ItinResourceProps } from "../types";
import { Signpost } from "@phosphor-icons/react";

export const ItineraryResource: ItinResourceProps = {
  name: "itineraries",
  list: ItineraryList,
  edit: ItineraryEdit,
  create: ItineraryCreate,
  icon: <Signpost />,
  recordRepresentation: (r) => r.name,
  options: { menu_group: "places" },
};
