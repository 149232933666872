import RegionCreate from "./RegionCreate";
import RegionEdit from "./RegionEdit";
import RegionList from "./RegionList";
// import RegionShow from "./RegionShow";
import { ItinResourceProps } from "../types";
import { MapTrifold } from "@phosphor-icons/react";

export const RegionResource: ItinResourceProps = {
  name: "regions",
  list: RegionList,
  // show: RegionShow,
  edit: RegionEdit,
  create: RegionCreate,
  icon: <MapTrifold />,
  recordRepresentation: (r) => r.name,
  options: { menu_group: "places" },
};
