import {
  useBlockNoteEditor,
  useComponentsContext,
  useDictionary,
  useSelectedBlocks,
} from "@blocknote/react";
import { useEffect, useState } from "react";
import { RiImageEditFill, RiExpandWidthFill } from "react-icons/ri";
import { block_note_schema } from "../BlockInput";
import { ImageSelectModal } from "./ImageSelectModal";

export const ImageToolbarbutton = () => {
  const dict = useDictionary();
  const Components = useComponentsContext()!;

  const editor = useBlockNoteEditor(block_note_schema);

  const selectedBlocks = useSelectedBlocks(editor);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    setIsOpen(false);
  }, [selectedBlocks]);

  const block = selectedBlocks.length === 1 ? selectedBlocks[0] : undefined;
  if (block === undefined || block?.type !== "image" || !editor.isEditable) {
    return null;
  }

  const label =
    dict.formatting_toolbar.file_replace.tooltip[block.type] || "Replace Image";
  return (
    <>
      <Components.FormattingToolbar.Button
        className={"bn-button"}
        onClick={() => setIsOpen(!isOpen)}
        isSelected={isOpen}
        mainTooltip={label}
        label={label}
        icon={<RiImageEditFill />}
      />
      <ImageSelectModal
        block={block}
        opened={isOpen}
        onClose={() => setIsOpen(false)}
      />
      <Components.FormattingToolbar.Button
        icon={<RiExpandWidthFill />}
        label={"Full Width"}
        mainTooltip="Full Width"
        isSelected={block.props.fullWidth}
        onClick={() =>
          editor.updateBlock(block, {
            props: { fullWidth: !block.props.fullWidth },
          })
        }
      />
    </>
  );
};
