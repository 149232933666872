import { ReactNode, useCallback, useEffect, useState } from "react";
import { ImageBlock } from "./types";

export const ResizeHandlesWrapper = (
  props: Required<ImageBlock> & {
    width: number;
    setWidth: (width: number) => void;
    children: ReactNode;
  }
) => {
  const [childHovered, setChildHovered] = useState<boolean>(false);
  const [resizeParams, setResizeParams] = useState<
    | {
        initialWidth: number;
        initialClientX: number;
        handleUsed: "left" | "right";
      }
    | undefined
  >(undefined);

  useEffect(() => {
    // Updates the child width with an updated width depending on the cursor X
    // offset from when the resize began, and which resize handle is being used.
    const windowMouseMoveHandler = (event: MouseEvent) => {
      let newWidth: number;

      if (props.block.props.textAlignment === "center") {
        if (resizeParams!.handleUsed === "left") {
          newWidth =
            resizeParams!.initialWidth +
            (resizeParams!.initialClientX - event.clientX) * 2;
        } else {
          newWidth =
            resizeParams!.initialWidth +
            (event.clientX - resizeParams!.initialClientX) * 2;
        }
      } else {
        if (resizeParams!.handleUsed === "left") {
          newWidth =
            resizeParams!.initialWidth +
            resizeParams!.initialClientX -
            event.clientX;
        } else {
          newWidth =
            resizeParams!.initialWidth +
            event.clientX -
            resizeParams!.initialClientX;
        }
      }

      // Min child width in px.
      const minWidth = 64;

      // Ensures the child is not wider than the editor and not smaller than a
      // predetermined minimum width.
      props.editor.updateBlock(props.block, { props: { fullWidth: false } });
      if (newWidth < minWidth) {
        props.setWidth(minWidth);
      } else if (
        newWidth > props.editor.domElement.firstElementChild!.clientWidth
      ) {
        props.setWidth(props.editor.domElement.firstElementChild!.clientWidth);
      } else {
        props.setWidth(newWidth);
      }
    };
    // Stops mouse movements from resizing the child and updates the block's
    // `width` prop to the new value.
    const windowMouseUpHandler = () => {
      setResizeParams(undefined);

      (props.editor as any).updateBlock(props.block, {
        props: {
          previewWidth: props.width,
        },
      });
    };

    if (resizeParams) {
      window.addEventListener("mousemove", windowMouseMoveHandler);
      window.addEventListener("mouseup", windowMouseUpHandler);
    }

    return () => {
      window.removeEventListener("mousemove", windowMouseMoveHandler);
      window.removeEventListener("mouseup", windowMouseUpHandler);
    };
  }, [props, resizeParams]);

  // Shows the resize handles when hovering over the child with the cursor.
  const childWrapperMouseEnterHandler = useCallback(() => {
    if (props.editor.isEditable) {
      setChildHovered(true);
    }
  }, [props.editor.isEditable]);

  // Hides the resize handles when the cursor leaves the child, unless the
  // cursor moves to one of the resize handles.
  const childWrapperMouseLeaveHandler = useCallback(() => {
    setChildHovered(false);
  }, []);

  // Sets the resize params, allowing the user to begin resizing the child by
  // moving the cursor left or right.
  const leftResizeHandleMouseDownHandler = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();

      setResizeParams({
        handleUsed: "left",
        initialWidth: props.width,
        initialClientX: event.clientX,
      });
    },
    [props.width]
  );
  const rightResizeHandleMouseDownHandler = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();

      setResizeParams({
        handleUsed: "right",
        initialWidth: props.width,
        initialClientX: event.clientX,
      });
    },
    [props.width]
  );

  return (
    <div
      // className={"bn-visual-media-wrapper"}
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        position: "relative",
        width: "fit-content",
      }}
      onMouseEnter={childWrapperMouseEnterHandler}
      onMouseLeave={childWrapperMouseLeaveHandler}
    >
      {props.children}
      {(childHovered || resizeParams) && (
        <>
          <div
            style={{
              left: "4px",
              position: "absolute",
              width: "8px",
              height: "30px",
              backgroundColor: "#000",
              border: "1px solid white",
              borderRadius: "4px",
              cursor: "ew-resize",
            }}
            onMouseDown={leftResizeHandleMouseDownHandler}
          />
          <div
            style={{
              right: "4px",
              position: "absolute",
              width: "8px",
              height: "30px",
              backgroundColor: "#000",
              border: "1px solid white",
              borderRadius: "4px",
              cursor: "ew-resize",
            }}
            onMouseDown={rightResizeHandleMouseDownHandler}
          />
        </>
      )}
    </div>
  );
};
