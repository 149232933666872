import { FormHelperText, Grid } from "@mui/material";
import { chain, get } from "lodash";
import {
  AutocompleteInput,
  BooleanInput,
  RadioButtonGroupInput,
  TextInput,
  required,
  useListContext,
} from "react-admin";
import { LocationFields } from "~/components";
import { ItinLoading } from "~/components/ItinLoading";
import { ImageField } from "~/components/images";
import { SingleImageInput } from "~/components/images/SingleImageInput";
import {
  EnumChoiceInput,
  PolymorphicInputs,
  usePolymorphic,
} from "~/components/inputs";
import ItinRichTextArea from "~/components/inputs/ItinRichTextArea";
import { useCurrentPlatform } from "~/context";
import { useStopSubTypes, useStopTypes } from "~/hooks";

export const StopFields = ({ itinerary }) => {
  const { data: target, type } = usePolymorphic();
  const { getTerm } = useCurrentPlatform();
  const { data = [] } = useListContext();
  const fallbackLonLat = chain(data)
    .findLast("fullLonLat")
    .get("fullLonLat", get(itinerary, "lonlat"))
    .value();
  const target_name = target?.name;
  const target_description = target?.description;
  const target_subtitle = target?.subtitle;

  const target_singular =
    type &&
    getTerm(type, {
      capitalize: false,
      plural: false,
    });

  const { data: main_types, isLoading } = useStopTypes();
  const { data: subtypes } = useStopSubTypes();

  if (isLoading) return <ItinLoading />;
  return (
    <Grid container width="75vw" columnSpacing={0}>
      <Grid item md={12}>
        <PolymorphicInputs target_types={["Region", "Listing"]} />
      </Grid>
      <Grid container item md={12}>
        <Grid item md={6} display="flex" gap={3}>
          <EnumChoiceInput source="status" isRadio={true} />
          <RadioButtonGroupInput
            source="display_type"
            choices={[
              { id: "step", name: "Step" },
              { id: "note", name: "Note" },
            ]}
            validate={[required()]}
          />
        </Grid>
        <Grid container item md={6} display="flex" flexDirection="row">
          <Grid item xs={12} display="flex">
            <BooleanInput
              label="Featured?"
              source="featured"
              sx={{ paddingTop: "10px" }}
            />

            <BooleanInput
              label="Allow check-ins?"
              source="checkinable"
              sx={{ paddingTop: "10px" }}
            />
          </Grid>
          <Grid item xs={12} display="flex">
            <BooleanInput source="show_on_app" />
            <BooleanInput source="show_on_web" />
          </Grid>
        </Grid>
      </Grid>
      <Grid container item md={12}>
        <Grid item md={6}></Grid>
        <Grid item md={6} display="flex"></Grid>
      </Grid>

      <Grid item container md={12} columnSpacing={2}>
        <Grid item md={6}>
          <Grid container>
            <TextInput
              source="title_override"
              label="Name"
              fullWidth
              helperText={
                !!target_name
                  ? `Leave blank to use the ${target_singular} name.`
                  : undefined
              }
              placeholder={target_name || undefined}
              validate={!target ? required() : undefined}
            />
            <TextInput
              source="subtitle"
              label="Subtitle"
              fullWidth
              helperText={
                !!target_name
                  ? `Leave blank to use the ${target_singular} subtitle.`
                  : undefined
              }
              placeholder={target_subtitle || undefined}
            />
            <Grid item md={5}>
              <AutocompleteInput
                label="Type"
                source="main_type"
                choices={main_types}
                onCreate={(name) => {
                  const newType = { id: name, name };
                  main_types.push(newType);
                  return newType;
                }}
              />
            </Grid>
            <Grid item md={1}></Grid>
            <Grid item md={5}>
              <AutocompleteInput
                label="Sub Type"
                source="subtype"
                choices={subtypes}
                optionValue="name"
                onCreate={(name) => {
                  const newType = { id: name, name };
                  subtypes.push(newType);
                  return newType;
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6}>
          {target && (
            <ImageField
              sx={{ height: "200px" }}
              source="hero.url"
              label={`${target_singular} Hero Image`}
              record={target}
            />
          )}
          <SingleImageInput
            label="Hero Image"
            source="hero"
            height={target ? "10vh" : "20vh"}
          />
          <FormHelperText>
            {target
              ? `Using ${target_singular} image, upload to override.`
              : ""}
          </FormHelperText>
        </Grid>
        <Grid item md={6}>
          <ItinRichTextArea
            key={target_description}
            source="chapter_write_up"
            height="250px"
            label="Description"
            helperText={
              target_description
                ? `Leave blank to use ${target_singular} description.`
                : undefined
            }
            placeholder={target_description}
          />
        </Grid>
        <Grid item md={6}>
          <LocationFields
            // height="20vh"
            fallbackCenterLonLat={fallbackLonLat}
            isRequired={false}
            targetBounds={target?.bounds}
            targetLonLat={target?.lonlat}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
