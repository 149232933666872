import { styled } from "@mui/material/styles";
import { ScopedCssBaseline } from "@mui/material";
import {
  ProfileProvider,
  CurrentPlatformProvider,
  GoogleMapsLoaderProvider,
} from "~/context";
import { Sidebar } from "./Sidebar";
import { AppBar } from "./AppBar";
import { ErrorBoundary } from "react-error-boundary";
// import { Error } from "react-admin";
import { Error } from "~/components/Error";
import { useEffect, useRef, useState } from "react";
import { ProfileDrawer } from "./ProfileDrawer";
import { AppLocationContext } from "@react-admin/ra-navigation";
import { useLocation } from "react-router-dom";
import { PREFIX, LayoutClasses } from "./layoutClasses";
// import { ReactQueryDevtools } from "react-query/devtools";

function ErrorButton() {
  const [count, setCount] = useState(0);
  const onClick = () => {
    setCount(count + 1);
  };
  useEffect(() => {
    if (count === 2) {
      throw new Error("I crashed!");
    }
  });

  return <button onClick={onClick}>Double click to create error</button>;
}
export const useScrollToTop = () => {
  const location = useLocation();
  const ref = useRef(null);
  useEffect(() => {
    if (
      location.state?._scrollToTop &&
      typeof window != "undefined" &&
      !!ref.current
    ) {
      ref.current.scrollTo(0, 0);
    }
  }, [location]);
  return ref;
};

export const Layout = ({ children, error: errorComponent }) => {
  const [errorInfo, setErrorInfo] = useState(null);
  const handleError = (error, info) => {
    setErrorInfo(info);
  };
  const ref = useScrollToTop();
  return (
    <ScopedCssBaseline enableColorScheme>
      <ProfileProvider>
        <CurrentPlatformProvider>
          <GoogleMapsLoaderProvider>
            <AppLocationContext hasDashboard>
              <ProfileDrawer />
              <StyledLayout>
                <Sidebar />
                <div className={LayoutClasses.contentWithAppbar}>
                  <AppBar />
                  <div
                    id="main-content"
                    className={LayoutClasses.content}
                    ref={ref}
                  >
                    <ErrorBoundary
                      onError={handleError}
                      FallbackComponent={({ error, resetErrorBoundary }) => (
                        <Error
                          error={error}
                          errorComponent={errorComponent}
                          errorInfo={errorInfo}
                          resetErrorBoundary={resetErrorBoundary}
                        />
                      )}
                    >
                      {/* <ErrorButton /> */}
                      {children}
                      {/* <ReactQueryDevtools /> */}
                    </ErrorBoundary>
                  </div>
                </div>
              </StyledLayout>
            </AppLocationContext>
          </GoogleMapsLoaderProvider>
        </CurrentPlatformProvider>
      </ProfileProvider>
    </ScopedCssBaseline>
  );
};

const StyledLayout = styled("div", {
  name: PREFIX,
  slot: "Root",
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "row",
    zIndex: 1,
    height: "100vh",
    backgroundColor: theme.palette.background.default,
    position: "relative",
    overflow: "hidden",
    [`& .${LayoutClasses.contentWithAppbar}`]: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      overflow: "hidden",
      flexBasis: 0,
    },
    [`& .${LayoutClasses.content}`]: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      overflow: "auto",
      backgroundColor: theme.palette.background.paper,
    },
    [`& .${LayoutClasses.sidebar}`]: {
      display: "flex",
      flexDirection: "column",
      width: "15%",
      [theme.breakpoints.down("md")]: {
        minWidth: "7%",
      },
      maxWidth: "240px",
      overflow: "auto",
      backgroundColor: "black",
    },
    [`& .${LayoutClasses.appBar}`]: {
      position: "static",
      backgroundColor: theme.palette.background.default,
      boxShadow: "inset 0px -1px 0px #F1F1F1",
    },
    [`& .${LayoutClasses.menu}`]: {
      flexGrow: 1,
      "&.RaMenu-open": {
        width: "auto",
      },
    },
    [`& .${LayoutClasses.tooltip}`]: {
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    [`& .${LayoutClasses.menuItem}`]: {
      minHeight: "48px",
      height: "auto",
      borderRight: "4px solid transparent",
      "&.RaMenuItemLink-active": {
        borderRightColor: theme.palette.primary.main,
        "& .RaMenuItemLink-icon": {
          color: theme.palette.primary.main,
        },
        backgroundColor: "#242D35",
      },
      "& .RaMenuItemLink-icon": {
        color: "white",
      },
    },
    [`& .${LayoutClasses.logo}`]: {
      margin: "25px 0 45px",
      height: 55,
    },
  };
});
