import { DataGridPro } from "@mui/x-data-grid-pro";
import dayjs from "dayjs";
import { Link } from "react-admin";
import { titleize } from "~/helpers";
import { ProgressBar } from "./ProgressBar";
import { ReportDataGridBar } from "./ReportDataGridBar";

export const ReportDataGrid = ({ data, name }) => {
  if (!data || !data.length) return null;
  const maxCount = data.reduce((max, obj) => {
    return obj.count > max ? obj.count : max;
  }, -Infinity);
  const linkable = Object.keys(data[0]).includes("resource");
  const columns = Object.keys(data[0])
    .filter((key) => key !== "resource" && key !== "resource_id")
    .map((key) => ({
      field: key,
      headerName: titleize(key),
      width: key === "count" && 180,
      flex: key !== "count" && 1,
      renderCell: (params) => {
        const value = params.value;
        if (key === "date" || key === "sent_at") {
          return dayjs(value, "YYYY-MM-DD").format("LL");
        }
        if (key === "percentage") {
          return <ProgressBar value={Number(value / maxCount)} />;
        }
        if (key === "count") {
          return <ReportDataGridBar value={Number(value)} max={maxCount} />;
        }
        return linkable ? (
          <Link to={`/${params.row["resource"]}/${params.row["resource_id"]}`}>
            {value}
          </Link>
        ) : (
          value
        );
      },
    }));

  const rows = data.map((row, index) => ({
    ...row,
    id: `${name}-${index}`,
  }));
  //, maxHeight: "750px"
  return (
    <div style={{ width: "100%" }}>
      <DataGridPro
        pagination
        rows={rows}
        columns={columns}
        initialState={{
          ...data.initialState,
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        pageSizeOptions={[5, 10, 25]}
        rowHeight={30}
        sx={{ paddingBottom: 2 }}
      />
    </div>
  );
};
