import { Box } from "@mui/material";
import { Labeled } from "react-admin";
import { LonLatFields } from "./LonLatFields";
import { MapDataProvider } from "./MapDataProvider";
import { MapField } from "./MapField";

export const LocationFields = (props) => {
  return (
    <MapDataProvider>
      <Labeled label="Location & Area" fullWidth>
        <Box
          height={props.height ? props.height : "60vh"}
          width="100%"
          marginTop={"0px"}
          marginBottom={"0px"}
        >
          <MapField toolButtons={true} {...props} />
        </Box>
      </Labeled>
      <LonLatFields {...props} />
    </MapDataProvider>
  );
};
