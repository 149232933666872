import { Card, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { createContext, useContext, useRef, useState, useEffect } from "react";
import { Form, Link, Notification, useGetOne, useLogin } from "react-admin";
import { useLocation } from "react-router-dom"; // Import useLocation hook
import Logo from "~/images/logo.png";
import Ismo from "~/images/ismo.png";
import { EmailForm } from "./EmailForm";
import { LoginSelect } from "./LoginSelect";
import { useNotifySignInError } from "./useNotifySignInError";
import { Sparkles } from "~/components";

export const Login = () => {
  const classes = useStyles();
  const containerRef = useRef();
  const [sign_up, setSignUp] = useState(false);
  const [email_auth, setEmailAuth] = useState(false);
  const [awaiting_verification, setAwaitingVerification] = useState(false);
  const [loading, setLoading] = useState(false);
  const [signupEmail, setSignupEmail] = useState(""); // State to store the signup email
  const [platformId, setPlatformId] = useState(false); // State to store the passed platform_id
  const login = useLogin();
  const notify = useNotifySignInError();
  const location = useLocation(); // Use the useLocation hook to access the current URL

  // Effect to set sign_up state and signupEmail based on URL parameters
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const isSignUp = params.get("signup") === "true";
    const email = params.get("signup_email") || ""; // Get the signup_email parameter
    const platform_id = params.get("platform_id") || ""; // Get the signup_email parameter
    setSignUp(isSignUp);
    setPlatformId(platform_id);
    setSignupEmail(email); // Set the signup email state
  }, [location]);
  // const { data: temporaryPlatform, isLoading } = useGetOne(
  //   "platforms",
  //   {
  //     id: platformId,
  //   },
  //   { enabled: platformId != false }
  // );
  const submit = (values) => {
    setAwaitingVerification(true);
    setLoading(true);
    login({ ...values, sign_up })
      .catch((error) => {
        const { message } = error;
        if (message === "emailNotVerfied") {
          setAwaitingVerification(true);
        } else {
          notify(error);
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <LoginContext.Provider
      value={{
        loading,
        sign_up,
        email_auth,
        awaiting_verification,
        setSignUp,
        setEmailAuth,
        setAwaitingVerification,
      }}
    >
      <Form onSubmit={submit}>
        <div className={classes.main} ref={containerRef}>
          <Card
            className={classes.card}
            sx={{
              bgcolor: "grey.50",
              maxWidth: email_auth ? 500 : 400,
              width: "100vw",
              overflow: "auto",
              padding: email_auth ? "32px" : "16px",
              borderRadius: "12px",
            }}
          >
            <div className={classes.avatar}>
              <img src={Logo} width={216} style={{ marginBottom: "24px" }} />
            </div>
            {signupEmail && (
              <>
                <Typography
                  sx={{
                    margin: "24px 15px",
                    fontSize: "1.5em",
                    lineHeight: "1.5em",
                  }}
                >
                  You've been invited to sign up with your e-mail,{" "}
                  <strong>{signupEmail}</strong>. You can use a matching google
                  account, or create a password now:
                </Typography>
              </>
            )}
            {email_auth ? (
              <EmailForm defaultEmail={signupEmail} />
            ) : (
              <Sparkles show={true} width="100%">
                <LoginSelect />
              </Sparkles>
            )}
            <Typography
              sx={{ fontSize: "1.5em", lineHeight: "1.5em", marginTop: "24px" }}
              textAlign="center"
            >{`${
              sign_up ? "Already" : "Don't"
            } have a Tourismo account?`}</Typography>
            <Typography
              sx={{ fontSize: "1.5em", lineHeight: "1.5em" }}
              textAlign="center"
            >
              <Link onClick={() => setSignUp((s) => !s)}>
                {`Sign ${sign_up ? "in" : "up"}`}
              </Link>
              {` now to ${sign_up ? "make magic" : "become a collaborator"}`}
            </Typography>
            <div className={classes.avatar}>
              <img src={Ismo} height="64" style={{ margin: "24px" }} />
            </div>
          </Card>
          <Notification />
        </div>
      </Form>
    </LoginContext.Provider>
  );
};

const LoginContext = createContext({
  sign_up: false,
  email_auth: false,
  awaiting_verification: false,
  loading: false,
  setSignUp: () => {},
  setEmailAuth: () => {},
  setAwaitingVerification: () => {},
});

export const useLoginContext = () => useContext(LoginContext);

const useStyles = makeStyles(
  (theme) => ({
    main: {
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
      height: "1px",
      alignItems: "center",
      justifyContent: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundColor: "#6d88f4" /*#263a96*/,
    },
    avatar: {
      display: "flex",
      justifyContent: "center",
    },
    icon: {
      backgroundColor: theme.palette.secondary[500],
      marginBottom: 0,
    },
  }),
  { name: "RaLogin" }
);
