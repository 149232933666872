import { InputAdornment, Toolbar as MuiToolbar } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ArrowClockwise, MagnifyingGlass, Plus } from "@phosphor-icons/react";
import pluralize from "pluralize";
import PropTypes from "prop-types";
import * as React from "react";
import { memo } from "react";
import {
  CreateButton,
  FilterContext,
  FilterForm,
  RefreshIconButton,
  SearchInput,
  useGetResourceLabel,
  useResourceContext,
  useResourceDefinition,
} from "react-admin";
import { ButtonWrapper } from "./ButtonWrapper";
import { FilterButton } from "./FilterButton";

const Search = (props) => {
  return (
    <SearchInput
      source="q"
      alwaysOn
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <MagnifyingGlass />
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
};
export const Toolbar = memo((props) => {
  const {
    filters = [],
    actions,
    className,
    search = true,
    refresh = true,
    ...rest
  } = props;
  const { hasCreate } = useResourceDefinition(props);
  const searchFilter = search ? [<Search source="q" alwaysOn />] : [];
  const allFilters = filters.concat(searchFilter);
  const resource = useResourceContext();
  const getResourceLabel = useGetResourceLabel();
  return (
    <FilterContext.Provider value={allFilters}>
      <Root className={className}>
        <ButtonWrapper>
          {!!filters.length && <FilterButton />}
          {Array.isArray(allFilters) && !!allFilters.length && <FilterForm />}
        </ButtonWrapper>
        <span />
        <ButtonWrapper>
          {refresh && (
            <RefreshIconButton
              icon={<ArrowClockwise />}
              sx={{
                border: "1px solid",
                borderColor: "grey.200",
                color: "grey.400",
              }}
            />
          )}
          {actions &&
            React.cloneElement(actions, {
              ...rest,
              ...actions.props,
            })}
          {hasCreate && (
            <CreateButton
              label={`New ${pluralize.singular(getResourceLabel(resource))}`}
              alignIcon="right"
              icon={<Plus />}
              variant="outlined"
            />
          )}
        </ButtonWrapper>
      </Root>
    </FilterContext.Provider>
  );
});

Toolbar.propTypes = {
  filters: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  // @ts-ignore
  actions: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
  // @ts-ignore
  exporter: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
};

const PREFIX = "RaListToolbar";

const Root = styled(MuiToolbar, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  position: "relative",
  justifyContent: "space-between",
  alignItems: "flex-end",
  width: "100%",
  padding: "0",
  minHeight: theme.spacing(5),
  [theme.breakpoints.down("sm")]: {
    backgroundColor: theme.palette.background.paper,
  },
  [theme.breakpoints.down("md")]: {
    margin: 0,
    flexWrap: "wrap",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "20px",
  },
  "& .MuiButton-endIcon": {
    color: theme.palette.grey[400],
  },
}));
