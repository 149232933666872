import { Grid } from "@mui/material";
import dayjs from "dayjs";
import { isDate } from "lodash";
import { useEffect, useRef } from "react";
import {
  AutocompleteArrayInput,
  BooleanField,
  BooleanInput,
  ChipField,
  DateTimeInput,
  FormTab,
  FunctionField,
  NumberField,
  NumberInput,
  ReferenceArrayField,
  ReferenceArrayInput,
  ReferenceManyField,
  SelectInput,
  SimpleForm,
  SingleFieldList,
  required,
  useCreateSuggestionContext,
  useRecordContext,
} from "react-admin";
import { useFormContext } from "react-hook-form";
import { DrawerDatagrid } from "~/components/drawer";
import { RRuleField } from "~/components/fields";
import { useCurrentPlatform } from "~/context";
import { LocationDrawer } from "~/resources/locations";
import { EventTimeSnippet } from "../EventTimeSnippet";

export const EditEventTimeTab = (props) => {
  const { id } = useRecordContext();
  const { getTerm } = useCurrentPlatform();
  const eventTerm = getTerm("event", { capitalize: true });
  return (
    <FormTab {...props} label={`${eventTerm} Times & Locations`}>
      <ReferenceManyField label="" reference="event_times" target="event_id">
        <DrawerDatagrid
          buttonLabel={eventTerm}
          Form={<EventTimeForm defaultValues={{ event_id: id }} />}
        >
          <NumberField source="id" />
          <EventTimeSnippet />
          <BooleanField source="all_day" />
          <RRuleField label="Repeats" source="rrule_string" />
          <FunctionField
            textAlign="right"
            label={`${eventTerm} Count`}
            render={(record) => record.all_start_times.length}
          />
          <FunctionField
            textAlign="right"
            label="Duration"
            render={(record) => `${(record.duration / 60 / 60).toFixed(2)} hrs`}
          />
          <ReferenceArrayField
            label="Locations"
            source="location_ids"
            reference="locations"
          >
            <SingleFieldList linkType={false}>
              <ChipField source="name" size="small" />
            </SingleFieldList>
          </ReferenceArrayField>
        </DrawerDatagrid>
      </ReferenceManyField>
    </FormTab>
  );
};

const EventTimeForm = (props) => {
  return (
    <SimpleForm {...props}>
      <EventTimeFormFields />
    </SimpleForm>
  );
};

const EventTimeFormFields = () => {
  const { watch, setValue } = useFormContext();
  const { rrule, all_day, ...rest } = watch();

  const handleRecurring = ({ target }) => {
    if (!target.checked) {
      setValue("rrule.freq", "");
      setValue("rrule.interval", null);
      setValue("rrule.count", null);
      setValue("rrule.until", null);
    }
    setValue("rrule", target.checked ? {} : null, {
      shouldDirty: true,
    });
  };
  const { getTerm } = useCurrentPlatform();
  const eventTerm = getTerm("event", {
    plural: false,
    capitalize: true,
  });
  return (
    <Grid container columnSpacing={3} maxWidth={600} mt={3}>
      <Grid container item xs={12}>
        <BooleanInput source="all_day" label="All Day?" />
        <BooleanInput source="sold_out" label={`${eventTerm} sold out?`} />
        <BooleanInput
          defaultValue={!!rrule}
          source="recurring"
          onChange={handleRecurring}
          label="Recurring?"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <ToggleableDateTimeInput
          show_time={!all_day}
          source="start"
          validate={required()}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <ToggleableDateTimeInput
          show_time={!all_day}
          end
          source="end"
          validate={required()}
        />
      </Grid>
      {!!rrule && (
        <>
          <Grid item xs={12} md={6}>
            <SelectInput
              InputLabelProps={{ shrink: false }}
              label="Frequency"
              source="rrule.freq"
              choices={[
                { id: "DAILY", name: "Daily" },
                { id: "WEEKLY", name: "Weekly" },
                { id: "MONTHLY", name: "Monthly" },
                { id: "YEARLY", name: "Annually" },
              ]}
              validate={required()}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ToggleableDateTimeInput
              show_time={false}
              end
              label="Until"
              source="rrule.until"
              defaultValue={null}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <NumberInput label="Interval" source="rrule.interval" fullWidth />
          </Grid>
          <Grid item xs={12} md={6}>
            <NumberInput label="Count" source="rrule.count" fullWidth />
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <ReferenceArrayInput source="location_ids" reference="locations">
          <AutocompleteArrayInput
            label="Locations"
            variant="outlined"
            optionText="name"
            fullWidth
            disableClearable
            sx={{ width: "100%" }}
            create={<EventTimeLocationForm />}
          />
        </ReferenceArrayInput>
      </Grid>
    </Grid>
  );
};

const EventTimeLocationForm = () => {
  const { filter, onCancel, onCreate } = useCreateSuggestionContext();
  return (
    <LocationDrawer
      defaultValue={filter}
      onCancel={onCancel}
      onCreate={onCreate}
    />
  );
};

const parseTime = (val, end) => {
  if (!val) return null;
  const time_stamp = end ? "T23:59:59.999Z" : "T00:00:00Z";
  return new Date(val + time_stamp).toISOString();
};

const formatDate = (val) => {
  if (!val) return "";
  return dayjs(isDate(val) ? val : val.replace("Z", "")).format("YYYY-MM-DD");
};

const ToggleableDateTimeInput = ({ source, show_time, end, ...props }) => {
  const { setValue, getValues } = useFormContext();
  const initial = useRef(true);
  useEffect(() => {
    if (!initial.current) {
      if (show_time) {
        const val = dayjs(getValues(source)).format("YYYY-MM-DD");
        setValue(source, parseTime(val, source));
      }
    } else {
      initial.current = false;
    }
  }, [show_time, source, end, getValues, setValue]);

  return (
    <DateTimeInput
      InputLabelProps={{ shrink: false }}
      type={!show_time ? "date" : "datetime-local"}
      format={!show_time ? formatDate : undefined}
      parse={!show_time ? (val) => parseTime(val, end) : undefined}
      fullWidth
      source={source}
      {...props}
    />
  );
};
